import { apiCall } from "@/setup/webApi";

export const accounts_getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", "TenderExpense/accounts/all")
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const heads_getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", "TenderExpense/heads/all")
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getByTenderId = (tenderId, pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();

  return new Promise((resolve, reject) => {
    apiCall("get", `TenderExpense/${tenderId}?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getAll = (tenderId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderExpense/${tenderId}/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const add = (tenderExpense) => {
  return new Promise((resolve, reject) => {
    apiCall("post", "TenderExpense", tenderExpense)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const update = (id, TenderExpense) => {
  return new Promise((resolve, reject) => {
    apiCall("patch", `TenderExpense/${id}`, TenderExpense)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};