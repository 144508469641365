<template>
    <qrcodeScanner @scanned="markAttendance" />
</template>

<script setup>
import { inject, computed } from 'vue';
import qrcodeScanner from '@/components/qrcodeScanner.vue';
import * as atService from '@/services/attendanceService';
import { useAuthStore } from "@/setup/stores/authStore";

const emits = defineEmits('scanned');
const authStore = useAuthStore();

const itsId = computed(() => {
    if (authStore.user)
        return authStore.user.itsId;
    else
        return null;
});

const loader = inject("loader");
const snackbar = inject("snackBar");

async function markAttendance(attendanceId) {
    loader.show();

    await atService.detail_add(attendanceId, { itsId: itsId.value.toString() })
        .then(() => snackbar.success("Attendance has been marked successfully"))
        .catch(error => {
            snackbar.error(error);
            console.log(error);
        })
        .finally(async () => {
            loader.hide();
            emits('scanned');
        });
}

</script>