import { apiCall } from "@/setup/webApi";




export const addDetail = (postData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `HideCollection`, postData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getAll = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();
  return new Promise((resolve, reject) => {
    apiCall("get", `HideCollection/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getCreatedBy = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `HideCollection`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const updateStatus = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `HideCollection/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const sendWhatsAppReceipt = (postData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `HideCollection/receipt`, postData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};


export const addHideDeliveryDetail = (postData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `HideCollection/delivery`, postData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getDeliveryDetailById = (Id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `HideCollection/delivery/${Id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
export const getDeliveryDetailByTeam = (teamId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `HideCollection/delivery/team/${teamId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
export const getAllDeliveryDetail = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();
  return new Promise((resolve, reject) => {
    apiCall("get", `HideCollection/delivery/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const updateHideDeliveryDetail = (updatedHideDelivery) => {
  return new Promise((resolve, reject) => {
    console.log(updateHideDeliveryDetail);
    apiCall("put", `HideCollection/delivery`, updatedHideDelivery)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};


export const getReport = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `HideCollection/report`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};