<template>
    <v-row v-if="'hijriYear' in tender">
        <v-col>
            <v-card>
                <v-card-title>
                    Eid-Ul-Adha {{ tender.hijriYear }}H
                </v-card-title>
                <v-card-text>
                    <v-btn href="/tender/list" size="small">Master List</v-btn>
                    <v-btn :href="`/tender/${tender.tenderId}/customers`" class="ml-2" size="small">Customers</v-btn>
                    <v-btn :href="`/tender/${tender.tenderId}/detail`" class="ml-2" size="small">Tender</v-btn>
                    <v-btn :href="`/tender/${tender.tenderId}/expenses`" class="ml-2" size="small">Expenses</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="'goatTotal' in hidesSummary">
        <v-col>
            <v-card color="deep-purple">
                <v-card-text>
                    <h1>{{ hidesSummary.sheepTotal }}</h1>
                    <h3 class="mt-2">Sheep</h3>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card color="indigo">
                <v-card-text>
                    <h1>{{ hidesSummary.goatTotal }}</h1>
                    <h3 class="mt-2">Goat</h3>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card color="blue">
                <v-card-text>
                    <h1>{{ hidesSummary.cowTotal }}</h1>
                    <h3 class="mt-2">Cow</h3>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card color="cyan">
                <v-card-text>
                    <h1>{{ hidesSummary.camelTotal }}</h1>
                    <h3 class="mt-2">Camel</h3>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script setup>
import { ref, inject, onMounted } from "vue";
import * as tenderService from "@/services/tenderService";

var loader = inject("loader");
var snackbar = inject("snackBar");

const tender = ref({});
const hidesSummary = ref({});

async function getData() {
    loader.show();

    const result = await tenderService.tender_getActive()
        .catch(ex => snackbar.error(ex));

    if (result) {
        tender.value = result;
        await getSummary();
    }

    loader.hide();
}

async function getSummary() {
    loader.show();

    const result = await tenderService.hides_summary(tender.value.tenderId)
        .catch(ex => snackbar.error(ex));

    if (result) {
        hidesSummary.value = result;
    }

    loader.hide();
}

onMounted(async () => {
    await getData();
    console.log(hidesSummary.value);
});

</script>