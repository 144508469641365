<template>
    <v-row>
        <v-col v-if="'title' in survey">
            <v-card>
                <v-card-text>
                    <h6 class="text-h6 font-weight-bold">
                        {{ survey.title }} <br />
                        <small><span class="text-red">From: {{ moment(survey.startDate).format("D-MMM-yyyy") }} To: {{
        moment(survey.endDate).format("D-MMM-yyyy") }}</span></small>
                    </h6>
                    <h6 class="text-subtitle-1" v-html="survey.description"></h6>
                </v-card-text>
            </v-card>
            <v-card class="my-2">
                <template v-for="(ques, i) in survey.questions" :key="ques.questionID">
                    <v-card-text>
                        <h3 class="mb-2">
                            {{ fillPlaceholders(ques.question) }} <small class="text-red font-weight-bold"
                                v-if="ques.isRequired">*Required</small> <br />
                            <small>{{ ques.description }}</small>
                        </h3>
                        <v-text-field v-if="ques.questionType == 'Textbox'"
                            @change="saveAnswer(ques, $event.target.value)" />

                        <v-text-field type="number" v-if="ques.questionType == 'Number'"
                            @change="saveAnswer(ques, $event.target.value)" />

                        <v-autocomplete v-model="dropDownAnswers[ques.questionID]" :items="getOptions(ques)"
                            v-if="ques.questionType.includes('Select')"
                            :multiple="ques.questionType == 'MultiSelect'" />

                        <v-radio-group v-if="ques.questionType == 'RadioGroup'"
                            @change="saveAnswer(ques, $event.target.value)">
                            <template v-for="opt in getOptions(ques)" :key="opt">
                                <v-radio :label="opt" :value="opt" />
                            </template>
                        </v-radio-group>
                        <v-divider v-if="i < survey.questions.length - 1" />
                    </v-card-text>
                </template>
            </v-card>
            <v-card>
                <v-card-text align="end">
                    <v-btn @click="submit">Submit</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col v-else>
            <v-card>
                <v-card-text>
                    <h6 class="text-h6 font-weight-bold text-red">Survey form is not available</h6>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, reactive, inject, onMounted, markRaw } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getById, addDetail } from '@/services/surveyService';
import moment from 'moment';
import { useAuthStore } from "@/setup/stores/authStore";

const loader = inject("loader");
const snackbar = inject("snackBar");
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const surveyId = route.params.id;
const survey = ref({});
const answers = markRaw([]);
const dropDownAnswers = reactive({});

async function getData() {
    loader.show();

    let result = await getById(surveyId, true)
        .catch(error => {
            console.log(error);
            snackbar.error(error);
        })
        .finally(async () => {
            loader.hide();
        });

    if(result) {
        survey.value = result;
    
        survey.value.questions.forEach(ques => {
            answers.push({ questionID: ques.questionID, answer: "" });
    
            if (ques.questionType == "MultiSelect")
                dropDownAnswers[ques.questionID] = [];
        });
    }
}

function fillPlaceholders(question) {
    var vals = {
        FullName: authStore.user.username,
        Team: authStore.user.organization,
        Section: authStore.user.section,
        Jamaat: authStore.user.jamaat,
        Rank: authStore.user.rank,
        ItsId: authStore.user.itsId,
    };

    Object.keys(vals).forEach(key => {
        console.log(question, key, vals[key]);
        question = question.replace(`[${key}]`, vals[key]);
    });

    return question;
}

function getOptions(ques) {
    return ques.options.split(",");
}

function saveAnswer(ques, value) {
    let ans = answers.find(x => x.questionID == ques.questionID);
    ans.answer = value;
}

async function submit() {
    let hasError = false;

    survey.value.questions.forEach(ques => {
        let ans = answers.find(x => x.questionID == ques.questionID);
        let dropdownans = dropDownAnswers[ques.questionID];

        if (dropdownans)
            ans.answer = dropdownans.toString();

        if (ques.isRequired && !ans.answer) {
            hasError = true;
            snackbar.error(`Answer for question: ${ques.question} is required. Please enter the answer and submit the form.`);
        }
    });

    if (hasError)
        return;

    loader.show();

    addDetail(surveyId, answers)
        .then(() => {
            snackbar.success("Your form has been submitted successfully");
        })
        .catch(error => {
            console.log(error);
            snackbar.error(error);
        })
        .finally(async () => {
            loader.hide();
        });


    setTimeout(() => router.push("/home"), 1000);
}

onMounted(async () => {
    await getData();
    console.log(authStore.user);
})

</script>