<template>
  <v-card>
    <v-card-text class="d-flex justify-space-between">
      <v-btn href="/tender" size="small">Dashboard</v-btn>
      <v-btn @click="dlgTender.show()" size="small">New Event</v-btn>
    </v-card-text>
  </v-card>
  <data-table class="mt-2" :tableData="tableData" disable-pagination>
    <template v-slot:[`item.tenderDate`]="{ item }">
      {{ moment(item.tenderDate).format("DD-MMM-YYYY") }}
    </template>
    <template v-slot:[`item.isActive`]="{ item }">
      <v-icon icon="mdi-check-circle" v-if="item.isActive" color="green" />
      <v-icon icon="mdi-close-circle" v-if="!item.isActive" color="red" />
    </template>
    <template v-slot:[`item.Actions`]="{ item }">
      <v-btn icon="mdi-pencil" size="x-small" class="my-1" @click="viewMember(item)" />
    </template>
  </data-table>

  <simple-dialog ref="dlgTender" title="New Tender" width="786">
    <template v-slot:content>
      <AddTenderView />
    </template>
  </simple-dialog>

  <!-- <data-table class="mt-2" :tableData="tableData" disable-pagination> -->
  <!-- 

    <template v-slot:[`item.Actions`]="{ item }">
      <v-btn size="small" class="mr-2" @click="() => viewDetail(item.raw)"><v-icon icon="mdi-magnify" size="small"
          left></v-icon>View</v-btn>
      <v-btn size="small" @click="() => editData(item.raw)"><v-icon icon="mdi-pencil" size="small"
          left></v-icon>Edit</v-btn>
    </template> -->
  <!-- </data-table> -->

  <!-- <simple-dialog ref="dlgTender" title="New Tender" width="786" @ok="saveData">
    <template v-slot:content>
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              type="date"
              label="Date"
              v-model="tender.tenderDate"
            />
            <v-text-field
              type="number"
              label="Hijri Year"
              v-model="tender.hijriYear"
            />
            <v-text-field
              type="number"
              label="Deposit Amount"
              v-model="tender.depositAmount"
            />
            <v-checkbox v-model="tender.isActive" label="Active"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </simple-dialog> -->
</template>

<script setup>
import { ref, reactive, onMounted, inject } from "vue";
import * as tenderService from "@/services/tenderService";
import moment from "moment";
import AddTenderView from "./AddTenderView";

const loader = inject("loader");
const snackbar = inject("snackBar");

const tableData = reactive({
  headers: [
    { title: "S. No", key: "SNo", width: "90px" },
    { title: "Tender Date", key: "tenderDate" },
    { title: "Hijri Year", key: "hijriYear", width: "110px" },
    { title: "Deposit Amount", key: "depositAmount" },
    { title: "Active", key: "isActive", width: "90px" },
    { title: "Actions", key: "Actions", width: "90px" },
  ],
  data: [],
});

// const tender = reactive({
//   tenderId: 0,
//   tenderDate: "",
//   hijriYear: 0,
//   depositAmount: 0,
//   isActive: false,
// });

const dlgTender = ref(null);

async function getData() {
  loader.show();

  let result = await tenderService.tender_getAll()
    .catch(ex => snackbar.error(ex));
  loader.hide();

  tableData.data = result;
}

// function newData() {
//   tender.tenderId = 0;
//   tender.tenderDate = "";
//   tender.hijriYear = "";
//   tender.depositAmount = 0;
//   tender.isActive = true;

//   dlgTender.value.show();
// }

// function editData(item) {
//   tender.tenderId = item.tenderId;
//   tender.tenderDate = moment(item.tenderDate).format("YYYY-MM-DD");
//   tender.hijriYear = item.hijriYear;
//   tender.depositAmount = item.depositAmount;
//   tender.isActive = item.isActive;

//   dlgTender.value.show();
// }

// async function saveData() {
//   loader.show();

//   var result = null;

//   if (tender.tenderId == 0) {
//     result = await newTender(tender).finally(() => {
//       loader.hide();
//     });
//   } else {
//     result = await editTender(tender.tenderId, tender).finally(() => {
//       loader.hide();
//     });
//   }

//   console.log(result);
//   await getData();
// }

// function viewDetail(item) {
//   router.push(`/tender/${item.tenderId}`);
// }

onMounted(async () => {
  await getData();
});
</script>./AddTenderView.vue
