import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "./stores/authStore";

import LoginPage from "@/views/LoginView";
import LoginAdminPage from "@/views/LoginAdminView";
import MainPage from "@/views/MainView";
import AuthPage from "@/views/AuthView";

import HomeView from "@/views/HomeView";
import MembersListView from "@/views/Members/MembersListView.vue";
import AddMemberView from "@/views/Members/AddMemberView.vue";
import EditMemberView from "@/views/Members/EditMemberView.vue";
import MembersProfileView from "@/views/Members/MembersProfileView.vue";

import UserRoles from '@/views/AccessControl/UserRoles.vue';
import UserRights from '@/views/AccessControl/UserRights.vue';
import UserDataPermissionView from '@/views/AccessControl/UserDataPermissionView.vue';

import MiqaatProcurementForm from '@/views/Miqaat/MiqaatProcurementForm.vue';

// import Miqaat from "@/views/Miqaat/MiqaatHome.vue"
// import MiqaatCreate from "@/views/Miqaat/CreateMiqaat.vue"
// import Events from "@/views/Events/EventHome.vue"
// import CreateEvents from "@/views/Events/CreateEvent.vue"

import SharafFormListView from "@/views/SharafForms/FormsListView.vue";
import SharafFormView from "@/views/SharafForms/FormView.vue";

import SurveyFormView from "@/views/SurveyForms/SurveyFormView.vue";

import TabudaatFormView from "@/views/TabudaatForm/TabudaatFormView.vue";

import AttendanceView from "@/views/Attendance/AttendanceView.vue";
import AttendanceListView from "@/views/Attendance/AttendanceListView.vue";

import TenderDashboardView from "@/views/Tender/DashboardView.vue";
import TenderListView from "@/views/Tender/Master/TenderListView.vue";
import TenderCustomersListView from "@/views/Tender/Customers/TenderCustomersListView";
import TenderDetailView from "@/views/Tender/TenderDetailView";
import TenderExpenseView from "@/views/Tender/Expenses/ExpenseListView";
import TenderExpenseReportView from "@/views/Tender/Expenses/ReportView";
// import TenderMainPage from "@/views/MainView";
// import TenderView from "@/views/Tender/TenderView";
// import ExpenseView from "@/views/Tender/Expenses/ExpenseView";
// import ExpenseHeaderView from "@/views/Tender/Expenses/ExpenseHeaderView";

import MemberUpliftmentFormView from "@/views/MemberUpliftmentForm/MemberUpliftmentFormView";
import MemberUpliftmentFormInchargeView from "@/views/MemberUpliftmentForm/MemberUpliftmentFormInchargeView";
import MemberUpliftmentFormViewDetailed from "@/views/MemberUpliftmentForm/MemberUpliftmentFormViewDetailed";
import CampRegistrationForm from "@/views/CampRegistrationForm/FormView";
import CampRegisteredMembers from "@/views/CampRegistrationForm/MembersRegisteredView";

import HideDetailAddFormView from "@/views/HidesCollectionForm/HideDetail/AddFormView";
import HideDetailListView from "@/views/HidesCollectionForm/HideDetail/FormListView";
import HideDetailAdminListView from "@/views/HidesCollectionForm/HideDetail/FormListAdminView";

import HideDeliveryAddFormView from "@/views/HidesCollectionForm/HideDelivery/AddFormView";
import HideDeliveryListView from "@/views/HidesCollectionForm/HideDelivery/FormListView";
import HideDeliveryEditFormView from "@/views/HidesCollectionForm/HideDelivery/EditFormView";
import HideDeliveryAdminListView from "@/views/HidesCollectionForm/HideDelivery/FormListAdminView";
import HideCollectionReportView from "@/views/HidesCollectionForm/ReportView";


const routes = [
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/login/admin",
    component: LoginAdminPage,
  },
  {
    path: "/auth",
    component: AuthPage,
  },
  {
    path: "/",
    component: MainPage,
    children: [
      {
        path: "/home", component: HomeView, meta: { title: "Home" }
      },
      {
        path: "/members", component: MembersListView, meta: { title: "Members List" }
      },
      {
        path: "/members/add", component: AddMemberView, meta: { title: "Add Member" }
      },
      {
        path: "/members/:its", component: MembersProfileView, meta: { title: "Members Profile" }
      },
      {
        path: "/members/:its/edit", component: EditMemberView, meta: { title: "Edit Member" }
      },
      {
        path: "/sharaf-forms", component: SharafFormListView, meta: { title: "Sharaf Forms" }
      },
      {
        path: "/sharaf-forms/:id", component: SharafFormView, meta: { title: "Sharaf Form Submission" }
      },
      {
        path: "/survey-forms/:id", component: SurveyFormView, meta: { title: "Survey Form Submission" }
      },
      {
        path: "/attendance", component: AttendanceListView, meta: { title: "Attendance List" },
      },
      {
        path: "/attendance/:id", component: AttendanceView, meta: { title: "Attendance Form" }
      },
      {
        path: "/upliftment-form", component: MemberUpliftmentFormView, meta: { title: "Upliftment Form" }
      },
      {
        path: "/upliftment-form-response", component: MemberUpliftmentFormInchargeView, meta: { title: "Upliftment Form Responses" }
      },
      {
        path: "/upliftment-form-detailed/:id", component: MemberUpliftmentFormViewDetailed, meta: { title: "Upliftment Form Details" }
      },
      {
        path: "/camp-form", component: CampRegistrationForm, meta: { title: "Camp Registration Form" }
      },
      {
        path: "/camp-registered-members", component: CampRegisteredMembers, meta: { title: "Camp Registered Members" }
      },
      {
        path: "access/user-roles", component: UserRoles, meta: { title: "User Roles" }
      },
      {
        path: "access/user-rights", component: UserRights, meta: { title: "User Rights" }
      },
      {
        path: "access/user-data", component: UserDataPermissionView, meta: { title: "User Data Permission" }
      },
      {
        path: "miqaat/procurement-form", component: MiqaatProcurementForm, meta: { title: "Miqaat Procurement Form" }
      },
      {
        path: "/tabudaat-forms/:id", component: TabudaatFormView, meta: { title: "Tabudaat Form Submission" }
      },
      {
        path: "/hide-detail", component: HideDetailAddFormView, meta: { title: "Hide Collection Form" }
      },
      {
        path: "/hide-detail-list", component: HideDetailListView, meta: { title: "Hide Collection List" }
      },
      {
        path: "/hide-detail-admin-list", component: HideDetailAdminListView, meta: { title: "Hide Detail Admin List" }
      },
      {
        path: "/hide-delivery", component: HideDeliveryAddFormView, meta: { title: "Hide Delivery Form" }
      },
      {
        path: "/hide-delivery/:id", component: HideDeliveryEditFormView, meta: { title: "Hide Delivery Edit Form" }
      },
      {
        path: "/hide-delivery-list", component: HideDeliveryListView, meta: { title: "Hide Delivery List" }
      },
      {
        path: "/hide-delivery-admin-list", component: HideDeliveryAdminListView, meta: { title: "Hide Delivery Admin List" }
      },
      {
        path: "/hide-collection-report", component: HideCollectionReportView, meta: { title: "Hide Collection Report" }
      },
      {
        path: "/tender", component: TenderDashboardView, meta: { title: "Eid-ul-Adha Tender Dashboard" }
      },
      {
        path: "/tender/list", component: TenderListView, meta: { title: "Tender Events List" }
      },
      {
        path: "/tender/:tenderId/customers", component: TenderCustomersListView, meta: { title: "Tender Customers List" }
      },
      {
        path: "/tender/:tenderId/detail", component: TenderDetailView, meta: { title: "Tender Detail" }
      },
      {
        path: "/tender/:tenderId/expenses", component: TenderExpenseView, meta: { title: "Tender Expenses" }
      },
      {
        path: "/tender/:tenderId/expenses/reports", component: TenderExpenseReportView, meta: { title: "Tender Expenses Reports" }
      },
      // {
      //   path: "/miqaat", component: Miqaat, meta: { title: "Miqaat" },
      // },
      // {
      //   path: "/miqaat/create", component: MiqaatCreate, meta: { title: "Create Miqaat" },
      // },
      // {
      //   path: "/event", component: Events, meta: { title: "Event" },
      // },
      // {
      //   path: "/event/create", component: CreateEvents, meta: { title: "Event" },
      // },
    ],
  },
  // {
  //   path: "/tender",
  //   component: TenderMainPage,
  //   children: [
  //     {
  //       path: "/tender", component: TenderView, meta: { title: "Tenders" }
  //     },
  //     {
  //       path: "/tender/detail/:id", component: TenderDetailView, meta: { title: "Tender Dashboard" },
  //     },
  //     {
  //       path: "/tender/customers/", component: CustomersView, meta: { title: "Customers" },
  //     },
  //     {
  //       path: "/tender/expenses", component: ExpenseView, meta: { title: "Expenses" },
  //     },
  //     {
  //       path: "/tender/expenseheads", component: ExpenseHeaderView, meta: { title: "Expense Heads" },
  //     },
  //   ],
  // },
];

export const useCreatedRouter = createRouter({
  history: createWebHistory("/"),
  routes,
});

useCreatedRouter.beforeEach((to) => {
  const authStore = useAuthStore();

  const title = to.meta.title;

  document.title = "BGT Pakistan";

  if (title) document.title = `${document.title} - ${title}`;

  if (!authStore.isAuthenticated && (to.path !== "/login" && to.path !== "/login/admin" && to.path !== "/auth")) {
    return { path: "/login" };
  }
});
