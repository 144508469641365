<template>
  <custom-dialog v-bind="$attrs" :model-value="showDialog" :is-critical="isCritical">
    <template v-for="(index, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <template v-slot:actions>
      <v-btn :color="isCritical ? 'white' : 'primary'" variant="text" @click="hide" v-if="!hideCancelButton">
        {{ cancelButtonText == undefined ? "Cancel" : cancelButtonText }}
      </v-btn>

      <v-btn :color="isCritical ? 'white' : 'primary'" @click="ok" v-if="!hideConfirmButton">
        {{ confirmButtonText == undefined ? "Save" : confirmButtonText }}
      </v-btn>
    </template>
  </custom-dialog>
</template>

<script setup>
import { ref } from "vue";
import CustomDialog from "./customDialog";

const props = defineProps({
  closeOnSave: {
    type: Boolean,
    default: true,
  },
  isCritical: Boolean,
  onConfirm: Function,
  confirmButtonText: String,
  cancelButtonText: String,
  hideConfirmButton: Boolean,
  hideCancelButton: Boolean,
});

const emit = defineEmits(["ok", "cancel"]);

defineExpose({
  show,
  hide,
});

const showDialog = ref(false);

function show() {
  showDialog.value = true;
}

function hide() {
  showDialog.value = false;
  emit("cancel");
}

function ok() {
  if (props.closeOnSave) showDialog.value = false;
  emit("ok");
}
</script>
