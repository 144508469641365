<template>
    <v-carousel show-arrows="hover" continuous hide-delimiter-background height="400px" progress="primary"
        v-model="slide" v-if="requests.length > 0">
        <v-carousel-item v-for="(request, i) in requests" :key="i">
            <div class="d-flex flex-column pa-5 align-center justify-center">
                <v-img :src="request.memberPictureInUniform" width="200px" height="200px" />
                <div>{{ request.itsId }}</div>
                <div class="text-subtitle-1">{{ request.memberFullName }}</div>
                <div class="text-subtitle-1">
                    From: <b>{{ request.fromStatusDescription }} </b> To: <b>{{ request.toStatusDescription }}</b>
                </div>
                <v-row class="w-100">
                    <v-col>
                        <v-btn size="small" block class="mt-5" @click="approve(request.requestId)">Approve</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="red-darken-2" size="small" block class="mt-5"
                            @click="delete_request(request)">Delete</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-carousel-item>
    </v-carousel>
    <div class="text-subtitle-1 font-weight-bold text-center" v-else>
        No Status Requests Pending
    </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue';
import * as mrService from '@/services/memberRequestService';

const loader = inject("loader");
const snackbar = inject("snackBar");
const confirmDialog = inject("confirmDialog");

const requests = ref([]);
const slide = ref(0);

async function getData() {
    loader.show();

    var result = await mrService.status_getPending()
        .catch(ex => snackbar.error(ex));

    loader.hide();

    if (result) {
        requests.value = result;
    }
}

async function approve(id) {
    loader.show();

    await mrService.status_approve(id)
        .then(async () => {
            snackbar.success("Status update has been approved and completed");
            await getData();
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function delete_request(request) {
    const is_delete = await confirmDialog.confirm("Delete Request", `Are you sure you want to delete status request of: ${request.memberFullName}? Please confirm`, "Yes", "No", true);
    
    if (!is_delete)
    return;

    loader.show();

    await mrService.status_delete(request.requestId)
        .then(async () => {
            snackbar.success("Status request has been deleted");
            await getData();
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

onMounted(async () => {
    await getData();
});

</script>