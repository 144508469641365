import LoadingDialog from "./loadingDialog.vue";

export default {
  install: (app) => {
    const emitter = app.config.globalProperties.$emitter;

    app.component("LoadingDialog", LoadingDialog);

    function show() {
      emitter.emit("toggleLoader", true);
    }

    function hide() {
      emitter.emit("toggleLoader", false);
    }

    app.provide("loader", { show, hide });
  },
};
