import { apiCall } from "@/setup/webApi";

export const transfer_getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberRequest/transfer/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const transfer_getPending = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberRequest/transfer/pending`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const transfer_add = (request) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `MemberRequest/transfer`, request)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const transfer_approve = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `MemberRequest/transfer/approve/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const transfer_delete = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `MemberRequest/transfer/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const status_getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberRequest/status/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const status_getPending = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberRequest/status/pending`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const status_add = (request) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `MemberRequest/status`, request)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const status_approve = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `MemberRequest/status/approve/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const status_delete = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `MemberRequest/status/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};