import { apiCall } from "@/setup/webApi";

export const getByTenderId = (tenderId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderDetail/${tenderId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const add = (tenderId, tenderCustomer) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `TenderDetail/${tenderId}`, tenderCustomer)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getReportByTenderId = (tenderId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderDetail/${tenderId}/report`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
