<template>
    <v-row v-if="'description' in event">
        <v-col>
            <v-card>
                <v-card-text>
                    <h6 class="text-h6 font-weight-bold">
                        {{ event.description }} - {{ moment(event.dutyDate).format("D-MMM-yyyy") }}<br />
                        <small><span class="text-red">
                                Reporting Time: {{ moment(event.reportingTime).format("hh:mm A") }}
                            </span></small><br />
                        <small>
                            <span class="text-red">
                                Scanning from: {{ moment(event.scanningStart).format("hh:mm A") }}
                                to {{ moment(event.scanningEnd).format("hh:mm A") }}
                            </span>
                        </small>
                    </h6>
                    <!-- <router-link to="/attendance">Back to Event</router-link> -->
                </v-card-text>
            </v-card>
            <v-card class="mt-3">
                <v-card-text>
                    <v-list density="compact" v-if="attendance.length > 0">
                        <v-list-item density="compact" v-for="(att, ix) in attendance" :key="att.member.itsId"
                            :title="`${ix + 1} - ${att.member.itsId} - ${att.member.fullName} - ${att.member.rank} (${att.member.organization})`"
                            :subtitle="formatTime(att.reportingTime)" :prepend-avatar="att.member.pictureInUniform">
                        </v-list-item>
                    </v-list>
                    <h4 v-else class="text-center">No Attendance Marked</h4>
                </v-card-text>
            </v-card>
            <v-card class="mt-3">
                <v-card-text>
                    <v-text-field v-model="selectedMemberId" label="Enter Its Number" @keydown.enter="addMember"
                        hide-details />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue';
import { useRoute } from "vue-router";
import * as atService from '@/services/attendanceService';
import moment from 'moment';

const loader = inject("loader");
const snackbar = inject("snackBar");
const router = useRoute();

const attendanceId = router.params.id;
const event = ref({});
const attendance = ref([]);
const selectedMemberId = ref("");

function formatTime(dt) {
    return moment(dt).format("DD-MMM-YYYY hh:mm A");
}

async function getData() {
    loader.show();

    let result = await atService.master_getById(attendanceId)
        .catch(error => console.log(error))
        .finally(async () => {
            await getDetail();
            loader.hide();
        });

    event.value = result;
}

async function getDetail() {
    let result = await atService.detail_getAllByMasterId(attendanceId)
        .catch(error => console.log(error))

    if (result)
        attendance.value = result;
}

async function addMember() {
    if (selectedMemberId.value == null) {
        snackbar.error("Please enter an Its number");
        return;
    }

    loader.show();

    await atService.detail_add(attendanceId, { itsId: selectedMemberId.value })
        .then(async () => {
            snackbar.success("Attendance has been marked successfully")
            await getDetail();
        })
        .catch(error => {
            snackbar.error(error);
            console.log(error);
        })
        .finally(() => {
            loader.hide();
        });

    selectedMemberId.value = null;
}

onMounted(async () => {
    await getData();
});

</script>