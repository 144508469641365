<template>
    <v-row>
        <v-col>
            <v-autocomplete label="Teams" v-model="transferRequest.toOrganizationId" :items="orgs" item-title="name"
                item-value="organizationId" />

            <v-autocomplete label="Sections" v-model="transferRequest.toSectionId" :items="sections"
                item-title="description" item-value="generalID" />
        </v-col>
    </v-row>
    <v-row>
        <v-col align="end">
            <v-btn variant="text" class="mr-2" color="black" @click="emit('onClose')">Cancel</v-btn>
            <v-btn @click="save">Save</v-btn>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import * as utService from '@/services/utilitiesService';
import * as mrService from '@/services/memberRequestService';

const loader = inject("loader");
const snackbar = inject("snackBar");

const emit = defineEmits('onSave', 'onClose');

const props = defineProps({
    member: {
        required: true,
    },
});

const orgs = ref([]);
const sections = ref([]);

const transferRequest = ref({
    itsId: props.member.itsId,
    toOrganizationId: null,
    toSectionId: null,
});

async function save() {
    loader.show();

    await mrService.transfer_add(transferRequest.value)
        .then(() => {
            snackbar.success("Transfer request has been generated. Please wait for the approval.");
            emit('onSave');
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

onMounted(async () => {
    var promises = [
        utService.getAllOrganizations(5),
        utService.getSections(),
    ]

    loader.show();

    const [getOrgs, getSections] = await Promise.all(promises);
    orgs.value = getOrgs;
    sections.value = getSections;

    loader.hide();
});

</script>