import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore("auth", () => {
  const router = useRouter();
  const tokenKey = "token";
  const userKey = "user";

  const token = ref(localStorage.getItem(tokenKey) || "");
  const userObj = ref(localStorage.getItem(userKey) || "");

  const isAuthenticated = computed(() => !!token.value);

  const user = computed(() => {
    return userObj.value ? JSON.parse(userObj.value) : null;
  });

  const setToken = (tokenStr) => {
    token.value = tokenStr;
    localStorage.setItem(tokenKey, tokenStr);
  };

  const setUser = (user) => {
    let user_str = JSON.stringify(user);
    userObj.value = user_str;
    localStorage.setItem(userKey, user_str);
  };

  const logout = () => {
    userObj.value = "";
    token.value = "";

    localStorage.setItem(tokenKey, "");
    localStorage.setItem(userKey, "");

    router.push("/login");
  };

  return { token, isAuthenticated, user, setUser, setToken, logout };
});