<template>
    <v-row>
        <v-col md="3">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    Roles
                    <v-btn size="small" @click="addNewRole">Add</v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-list v-if="roles.length > 0" density="compact">
                        <template v-for="role in roles" :key="role.roleId">
                            <v-list-item :title="role.roleName" :value="role" @click="selectRole(role)">
                                <template v-slot:append>
                                    <v-btn variant="text" size="sm" icon="mdi-account-group" color="info" class="mr-2"
                                        @click.stop="editUsers(role)" />
                                    <v-btn variant="text" size="sm" icon="mdi-pencil" color="info" class="mr-2"
                                        @click.stop="editRole(role)" />
                                    <v-btn variant="text" size="sm" icon="mdi-delete" color="error"
                                        @click.stop="deleteRoleItem(role)" />
                                </template>
                            </v-list-item>
                        </template>
                    </v-list>
                    <h5 class="text-subtitle-2 text-center pa-5" v-else>No Data Available</h5>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col md="9">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    Permissions <small class="text-grey-darken-1 font-weight-bold">{{ selectedRole.roleName }}</small>
                    <v-btn size="small" @click="savePermission" :disabled="modulesTableData.data.length == 0">Save</v-btn>
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                    <data-table :tableData="modulesTableData" disable-pagination class="elevation-0">
                        <template v-slot:[`item.options`]="{ item }">
                            <div class="d-flex justify-start">
                                <v-checkbox v-for="rt in item.options" v-model="item.rights" :value="rt"
                                    :label="getRightName(rt)" hide-details="auto" :key="`rt_${item.moduleId}_${rt}`"
                                    class="flex-grow-0" />
                            </div>
                        </template>
                    </data-table>
                    <div class="d-flex justify-end pa-4">
                        <v-btn size="small" @click="savePermission"
                            :disabled="modulesTableData.data.length == 0">Save</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <simple-dialog ref="dlgRole" width="500" title="Role" @ok="submitForm">
        <template v-slot:content>
            <v-form>
                <v-row>
                    <v-col>
                        <v-text-field label="Role Name" v-model="role.roleName" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </simple-dialog>

    <simple-dialog ref="dlgUsers" width="800" title="Role" @ok="addRoleUser" hide-confirm-button cancel-button-text="Close">
        <template v-slot:content>
            <v-form @submit.prevent="addRoleUser">
                <v-row>
                    <v-col>
                        <div class="d-flex">
                            <v-autocomplete label="User" :items="userSearch.data" v-model="userSearch.selectedItsId"
                                v-model:search="userSearch.search" @update:search="debouncedGetMembers"
                                item-title="fullName" item-value="itsId" />
                            <v-btn type="submit" class="ml-2">Add</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
            <v-list density="compact" style="height: 450px; overflow: auto;">
                <v-list-item v-for="user in userSearch.selectedUsers" :key="user.itsId"
                    :title="`${user.itsId} - ${user.memberFullName}`"
                    :subtitle="`${user.memberOrganizationName} - ${user.memberRankDescription} - ${user.memberSectionDescription}`">
                    <template v-slot:append>
                        <v-btn icon="mdi-delete" size="small" color="error" @click="deleteRoleUser(user)" />
                    </template>
                </v-list-item>
            </v-list>
        </template>
    </simple-dialog>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue';
import * as acService from '@/services/accessControlService.js';
import * as memService from '@/services/memberService';
import debounce from 'lodash/debounce';

const loader = inject("loader");
const confirmDialog = inject("confirmDialog");
const snackbar = inject("snackBar");

const roles = ref([]);
const selectedRole = ref({});
const userSearch = reactive({
    search: '',
    data: [],
    selectedItsId: null,
    selectedUsers: [],
});
const debouncedGetMembers = ref(null);

const modulesTableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Group", key: "parentName" },
        { title: "Name", key: "name" },
        { title: "Rights", key: "options" },
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});

var dlgRole = ref(null);
var dlgUsers = ref(null);

const role = reactive({
    roleId: 0,
    roleName: '',
    isPublic: false,
});

function getRightName(rt) {
    switch (rt) {
        case "C":
            return "Add";
        case "R":
            return "View";
        case "U":
            return "Edit";
        case "D":
            return "Delete";
        case "MA":
            return "Mark Attendance";
        default:
            return null;
    }
}

async function getRolesData() {
    loader.show();

    await acService.getRoles()
        .then(result => roles.value = result)
        .catch(ex => console.log(ex));

    loader.hide();
}

async function selectRole(item) {
    selectedRole.value = item;
    await getModulesData(selectedRole.value.roleId);
}

function addNewRole() {
    role.roleId = 0;
    role.roleName = '';
    role.isPublic = false;

    dlgRole.value.title = "New Role";
    dlgRole.value.show();
}

function editRole(item) {
    role.roleId = item.roleId;
    role.roleName = item.roleName;
    role.isPublic = false;

    dlgRole.value.title = "Edit Role";
    dlgRole.value.show();
}

async function deleteRoleItem(item) {
    let isDelete = await confirmDialog.confirm("Delete Role", `Are you sure you want to delete role: ${item.roleName}? Please confirm.`, "Yes", "No", true);

    if (isDelete) {
        loader.show();

        let result = await acService.deleteRole(item.roleId)
            .catch(ex => snackbar.error(ex));

        if (result) {
            snackbar.success("Role has been deleted successfully");
            selectedRole.value = {};
            await getRolesData();
        }

        loader.hide();
    }
}

async function submitForm() {
    if (role.roleName === '') {
        snackbar.error("Please enter a role name");
        return;
    }

    loader.show();

    let apiCall = null;

    if (role.roleId != 0)
        apiCall = acService.updateRole(role.roleId, role);
    else
        apiCall = acService.addRole(role);

    await apiCall
        .then(async result => {
            console.log(result);
            snackbar.success("Data has been saved successfully");
            await getRolesData();
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function getModulesData(roleId) {
    loader.show();

    await acService.getModulesForRoles(roleId)
        .then(result => {
            console.log(result);
            modulesTableData.data = result;
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function savePermission() {
    loader.show();

    let result = await acService.addPermissionForRoles(selectedRole.value.roleId, modulesTableData.data)
        .catch(ex => snackbar.error(ex))

    if (result)
        snackbar.success("Permissions have been updated");

    loader.hide();
}

async function getRoleUsers() {
    userSearch.selectedUsers = [];

    loader.show();

    const roleId = selectedRole.value.roleId;
    let result = await acService.getUsersForRoles(roleId)
        .catch(ex => snackbar.error(ex));

    if (result)
        userSearch.selectedUsers = result;

    loader.hide();
}

async function getMembers() {
    if (!userSearch.search)
        return;

    loader.show();

    let pageContext = {
        search: userSearch.search,
        pageNumber: 1,
        pageSize: 5,
        organizationId: 0,
        sectionId: 0,
        statusId: 0
    }

    let result = await memService.getAll(pageContext)
        .catch(ex => snackbar.error(ex));

    if (result) {
        userSearch.data = result.items;
    }

    loader.hide();
}

async function editUsers(role) {
    selectedRole.value = role;
    await getRoleUsers();
    dlgUsers.value.show();
}

async function addRoleUser() {
    if (!userSearch.selectedItsId)
        return;

    loader.show();

    const roleId = selectedRole.value.roleId;

    const result = await acService.addUserForRoles(roleId, { roleId: roleId, itsId: userSearch.selectedItsId })
        .catch(ex => snackbar.error(ex));

    if (result)
        await getRoleUsers();

    loader.hide();
}

async function deleteRoleUser(user) {
    let isDelete = await confirmDialog.confirm("Remove User", `Are you sure you want to remove user: ${user.memberFullName}? Please confirm.`, "Yes", "No", true);

    if (!isDelete)
        return;

    loader.show();

    const roleId = selectedRole.value.roleId;
    await acService.deleteUserForRoles(roleId, user.itsId)
        .catch(ex => snackbar.error(ex));

    await getRoleUsers();

    loader.hide();
}

onMounted(async () => {
    debouncedGetMembers.value = debounce(getMembers, 750);
    await getRolesData();
});

</script>