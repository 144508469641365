<template>
    <v-row v-if="'fullName' in member">
        <v-col cols="12">
            <v-card>
                <v-card-text class="d-flex pa-0">
                    <v-img :src="member.pictureInUniform" height="150px" max-width="160px" cover />
                    <v-divider vertical />
                    <div class="pa-4 flex-grow-1">
                        <h5 class="text-h6 font-weight-bold">{{ member.fullName }}</h5>
                        <h5 class="text-h6">{{ member.itsId }}</h5>
                        <h5 class="text-h6">{{ member.rank.description }}</h5>
                        <h5 class="text-h6">{{ member.organization.name }} - {{ member.section.description }}</h5>
                    </div>
                    <v-divider vertical />
                    <div class="pa-4 d-flex flex-column justify-center" style="width:175px;">
                        <h5 class="text-h4 font-weight-bold text-center text-green">0</h5>
                        <h5 class="text-h6 text-center">Pts</h5>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <!-- <v-col cols="6">
            <v-card>
                <v-card-text class="d-flex">
                    <div class="flex-grow-0 ma-2">
                        <v-img height="125px" width="125px" />
                        <h6 class="text-subtitle-1 text-center">Polite</h6>
                    </div>
                    <div class="flex-grow-0 ma-2">
                        <v-img height="125px" width="125px" />
                        <h6 class="text-subtitle-1 text-center">Best Member</h6>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card>
                <v-card-text class="d-flex">
                    <v-list>
                        <v-list-item title="NKD" subtitle="2022-1444H" />
                        <v-list-item title="MKD" subtitle="2022-1444H" />
                        <v-list-item title="NDI" subtitle="2022-1444H" />
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col> -->
        <v-col>
            <v-card>
                <v-card>
                    <v-card-title>Personal</v-card-title>
                    <v-card-text>
                        <v-text-field label="Home Address" v-model="member.homeAddress" readonly />
                        <div class="d-flex">
                            <v-text-field class="flex-grow-1" label="Date Of Birth" v-model="member.dateOfBirth" readonly />
                            <div class="px-3 py-2 text-subtitle-1">{{ member.age }} Years</div>
                        </div>
                        <v-text-field label="Marital Status" v-model="member.maritalStatus" readonly />
                        <v-text-field label="House Status" readonly />
                        <v-text-field label="Blood Group" v-model="member.bloodGroup" readonly />
                        <v-row>
                            <v-col>
                                <v-text-field label="Weight" v-model="member.weight" readonly />
                            </v-col>
                            <v-col>
                                <v-text-field label="Height" v-model="member.height" readonly />
                            </v-col>
                        </v-row>
                        <v-text-field label="Other Health Issues" v-model="member.otherHealthIssues" readonly />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card>
                    <v-card-title>Professional</v-card-title>
                    <v-card-text>
                        <v-text-field label="Education Level" v-model="member.educationLevel"  readonly/>
                        <v-text-field label="Degree" v-model="member.degree" readonly />
                        <v-text-field label="Occupation" v-model="member.occupation" readonly />
                        <v-text-field label="Specialization" v-model="member.occupationField" readonly />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card>
                    <v-card-title>Idara</v-card-title>
                    <v-card-text>
                        <div class="d-flex">
                            <v-text-field label="Date Of Joining" v-model="member.dateOfJoining" readonly />
                            <div class="px-3 py-2 text-subtitle-1">{{ moment().diff(member.dateOfJoining, 'years') }} Years</div>
                        </div>
                        <v-text-field label="Hifz Status" v-model="member.quranHifz" readonly />
                        <v-text-field label="Deeni Taleem" v-model="member.deeniTaalim" readonly />
                        <v-text-field label="Other Organizations" readonly />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import { useRoute } from "vue-router";
import * as memService from '@/services/memberService';
import moment from 'moment';

const route = useRoute();
const loader = inject("loader");

const itsId = route.params.its;
const member = ref({});

async function getData(its) {
    loader.show();

    let result = await memService.getProfileById(its)
        .finally(() => {
            loader.hide();
        });

    member.value = result;
    member.value.dateOfBirth = moment(member.value.dateOfBirth).format("DD-MMM-YYYY");
    member.value.dateOfJoining = moment(member.value.dateOfJoining).format("DD-MMM-YYYY");
}

onMounted(async () => {
    await getData(itsId);
    console.log(member.value);
});
</script>