import { apiCall } from "@/setup/webApi";

export const getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", "TenderCustomer/all")
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderCustomer/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const add = (tenderCustomer) => {
  return new Promise((resolve, reject) => {
    apiCall("post", "TenderCustomer", tenderCustomer)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const update = (id, tenderCustomer) => {
  return new Promise((resolve, reject) => {
    apiCall("patch", `TenderCustomer/${id}`, tenderCustomer)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const letter = (tenderId, customerId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderCustomer/${tenderId}/letter/${customerId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};