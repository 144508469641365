<template>
    <v-form @submit.prevent="saveData">
        <v-row>
            <v-col cols="12" md="4">
                <v-autocomplete label="Account" v-model="expense.expenseAccountId" :items="accounts"
                    item-title="accountName" item-value="expenseAccountId" hide-details />
            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete label="Team" v-model="expense.organizationId" :items="orgs" item-title="name"
                    item-value="organizationId" hide-details />
            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete label="Head" v-model="expense.expenseHeadId" :items="heads" item-title="expenseHead"
                    item-value="expenseHeadId" hide-details />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-text-field label="Particulars" v-model="expense.particulars" hide-details />
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field label="Amount" v-model="expense.amount" hide-details />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="end">
                <v-btn class="mr-2" variant="text">Clear</v-btn>
                <v-btn type="submit">Save</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import * as teService from "@/services/tenderExpenseService";
import * as utService from '@/services/utilitiesService';

const props = defineProps(["tenderId"]);
const emit = defineEmits(["onSave"]);

const loader = inject("loader");
const snackbar = inject("snackBar");

const orgs = ref([]);
const accounts = ref([]);
const heads = ref([]);

const expense = ref({
    tenderId: props.tenderId,
    expenseAccountId: null,
    organizationId: null,
    expenseHeadId: null,
    particulars: null,
    amount: null,
});

async function saveData() {
    loader.show();

    const result = await teService.add(expense.value)
        .catch(ex => snackbar.error(ex));

    if (result) {
        snackbar.success("Expense record has been added successfully");
        emit("onSave");
    }

    loader.hide();
}

onMounted(async () => {
    var promises = [teService.accounts_getAll(), teService.heads_getAll(), utService.getOrganizations(5)];

    loader.show();

    const [getAccounts, getHeads, getOrgs] = await Promise.all(promises);

    accounts.value = getAccounts;
    heads.value = getHeads;
    orgs.value = getOrgs;

    loader.hide();
});
</script>