<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text align="end">
                    <v-btn size="small" @click="navigateHideForm()">Add New</v-btn>
                </v-card-text>
            </v-card>
            <data-table :tableData="tableData" :disablePagination="true" class="mt-3">
                <template v-slot:[`item.receiptSend`]="{ item }">
                    <v-chip label color="success" variant="flat" v-if="item.receiptSent === true">Receipt
                        Sent</v-chip>
                    <v-btn prepend-icon="mdi-message" size="small" color="success" v-else
                        :disabled="item.receiptSent === true" class="my-1" @click="sendReceipt(item)">Send
                        Receipt</v-btn>
                </template>
                <template v-slot:[`item.Void`]="{ item }">
                    <v-chip label color="error" variant="flat" v-if="item.status === 'Void'">Record Void</v-chip>
                    <v-btn prepend-icon="mdi-cancel" size="small" color="error" v-else
                        :disabled="item.status === 'Void'" class="my-1"
                        @click="voidHideDetail(item.hideDetailId)">Void</v-btn>
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script setup>
import { reactive, onMounted, inject } from 'vue';
import { getCreatedBy, updateStatus, sendWhatsAppReceipt } from '@/services/hideCollectionService';
import { useRouter } from 'vue-router'

const router = useRouter()
const loader = inject("loader");
const snackbar = inject("snackBar");
const confirmDialog = inject("confirmDialog");

const tableData = reactive({
    headers: [
        { title: "ITS ID", key: "itsId" },
        { title: "Full Name", key: "fullName" },
        { title: "Email", key: "email" },
        { title: "Mobile", key: "mobile" },
        { title: "Sheep Count", key: "sheepCount" },
        { title: "Goat Count", key: "goatCount" },
        { title: "Cow Count", key: "cowCount" },
        { title: "Camel Count", key: "camelCount" },
        { title: "Send Receipt", key: "receiptSend" },
        { title: "Void", key: "Void" },
    ],
    data: [],
});

function navigateHideForm() {
    router.push(`/hide-detail`);

}

async function getData() {
    loader.show();
    try {
        const result = await getCreatedBy();
        tableData.data = result;
    } catch (error) {
        snackbar.error(error.message);
    } finally {
        loader.hide();
    }
}

async function voidHideDetail(id) {
    var isVoid = await confirmDialog.confirm("Void Hide detail", `Are you sure you want to void this record? Please confirm.`, "Yes", "No", true);

    if (!isVoid)
        return;

    loader.show();
    try {
        await updateStatus(id);
        await getData();
        snackbar.success('Collection record is now void');
    } catch (error) {
        snackbar.error(error.message);
    } finally {
        loader.hide();
    }
}

async function sendReceipt(hideDetail) {
    loader.show();
    try {
        await sendWhatsAppReceipt(hideDetail);
        await getData();
        snackbar.success('WhatsApp Receipt sent');
    } catch (error) {
        snackbar.error(error.message);
    } finally {
        loader.hide();
    }
}

onMounted(async () => {
    await getData();
});
</script>