import { apiCall } from "@/setup/webApi";
import { useAuthStore } from "@/setup/stores/authStore";

export const login = (formData) => {
  const authStore = useAuthStore();

  return new Promise((resolve, reject) => {
    apiCall("post", "Authentication/Login", formData)
      .then((response) => {
        authStore.setToken(response.token);

        authStore.setUser({
          itsId: response.itsId,
          username: response.username,
          organizationId: response.organizationId,
          organization: response.organization,
          sectionId: response.sectionId,
          section: response.section,
          rankId: response.rankId,
          rankOrder: response.rankOrder,
          rank: response.rank,
          jamaat: response.jamaat,
          email: response.email,
          mobile: response.mobile,
          isIncharge: response.isIncharge,
        });

        resolve(true);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
