import { apiCall } from "@/setup/webApi";

export const tender_getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", "TenderMaster/all")
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const tender_getActive = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", "TenderMaster/active")
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const tender_getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderMaster/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const tender_add = (tender) => {
  return new Promise((resolve, reject) => {
    apiCall("post", "TenderMaster", tender)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const tender_update = (id, tender) => {
  return new Promise((resolve, reject) => {
    apiCall("patch", `TenderMaster/${id}`, tender)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const hides_summary = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TenderMaster/${id}/hides/summary`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};