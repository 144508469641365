<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-text class="d-flex justify-space-between">
                    <v-btn href="/tender" size="small">Dashboard</v-btn>
                    <v-btn href="./expenses/reports" color="brown" size="small">Reports</v-btn>
                </v-card-text>
            </v-card>
            <v-card class="mt-3">
                <v-card-text>
                    <AddExpenseView :tenderId="tenderId" @onSave="getData" />
                </v-card-text>
            </v-card>
            <v-card class="mt-3">
                <v-card-text class="d-flex">
                    <v-text-field label="Search" v-model="pageContext.search" @keydown.enter="getData" hide-details />
                </v-card-text>
            </v-card>
            <data-table class="mt-3" :tableData="tableData" @pageChanged="pageChanged"></data-table>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from "vue";
import * as teService from "@/services/tenderExpenseService";
import { useRoute } from 'vue-router';
import AddExpenseView from "./AddExpenseView.vue";

const loader = inject("loader");
// const snackbar = inject("snackBar");

const pageContext = reactive({
    search: "",
    pageNumber: 1,
    pageSize: 20,
    organizationId: 0,
    expenseAccountId: 0,
    expenseHeadId: 0
});

const tableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Team", key: "organizationName" },
        { title: "Account", key: "accountName" },
        { title: "Head", key: "expenseHead" },
        { title: "Amount", key: "amount" },
        { title: "Actions", key: "Actions" },
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});

const route = useRoute();

const tenderId = route.params.tenderId;
const accounts = ref([]);
const heads = ref([]);

async function getData() {
    loader.show();

    let result = await teService.getByTenderId(tenderId, pageContext)
        .catch(error => console.log(error))
        .finally(() => {
            loader.hide();
        });

    if (result) {
        tableData.data = result.items;
        tableData.paginationContext = {
            pageSize: pageContext.pageSize,
            currentPage: result.currentPage,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
            hasNext: result.hasNext,
            hasPrevious: result.hasPrevious,
        };
    }
}

async function pageChanged(pageNo) {
    pageContext.pageNumber = pageNo;
    await getData();
}

onMounted(async () => {
    var promises = [teService.accounts_getAll(), teService.heads_getAll()];

    loader.show();

    const [getAccounts, getHeads] = await Promise.all(promises);

    accounts.value = getAccounts;
    heads.value = getHeads;

    loader.hide();

    await getData();
});

</script>