<template>
    <v-row>
        <v-col md="4">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    Members
                    <v-btn size="small"><v-icon icon="mdi-plus-circle" /> Add</v-btn>
                </v-card-title>
                <v-card-text>

                </v-card-text>
            </v-card>
        </v-col>
        <v-col md="8">
            <v-card>
                <v-card-title>
                    Modules
                </v-card-title>
                <v-card-text>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>

</script>