<template>
    <QrcodeStream @decode="onDecode" @track="drawOutline" />
</template>

<script setup>
import { QrcodeStream } from 'vue3-qrcode-reader';

const emits = defineEmits(['scanned']);

function onDecode(decodeString) {
    emits('scanned', decodeString);
}

function drawOutline(decodeData, context) {
    var points = [];

    for (var k in decodeData = {}) {
        switch (k) {
            case 'topLeftCorner':
                points[0] = decodeData[k];
                break;
            case 'topRightCorner':
                points[1] = decodeData[k];
                break;
            case 'bottomRightCorner':
                points[2] = decodeData[k];
                break;
            case 'bottomLeftCorner':
                points[3] = decodeData[k];
                break;
            default:
                break;
        }
    }

    console.log(context);

    context.lineWidth = 20;
    context.strokeStyle = "green";
    context.beginPath();

    context.moveTo(points[0].x, points[0].y);

    for(const {x,y} of points) {
        context.lineTo(x, y);
    }

    context.lineTo(points[0].x, points[0].y);
    context.closePath();
    context.stroke();

}

</script>