<template>
    <v-row>
        <v-col cols="12">
            <v-card class="mb-3">
                <v-card-text class="d-flex">
                    <v-row>
                        <v-col md="6" cols="12" class="d-flex">
                            <v-text-field label="Search" v-model="pageContext.search" hideDetails class="mr-2"
                                @keydown.enter="getData" />
                        </v-col>
                        <v-col md="6" cols="12" class="d-flex align-center">
                            <v-btn icon="mdi-filter-multiple" size="small"
                                @click="showFilterDialog = !showFilterDialog" />
                            <v-spacer />
                            <v-btn size="small" @click="navigateHideForm()">Add New</v-btn>
                        </v-col>
                        
                    </v-row>
                    </v-card-text>
                    <v-card-text class="d-flex">
                    <v-row>
                        <v-col cols="6">
                        <v-toolbar color="grey-lighten-4" title="Total">
                            <v-btn stacked>
                            <v-badge
                                :content="totalGoatCount"
                                inline
                            >
                            </v-badge>
                            Goats
                            </v-btn>

                            <v-btn stacked>
                            <v-badge
                                :content="totalCowCount"
                                inline
                            >
                            </v-badge>
                            Cows
                            </v-btn>
                            
                            <v-btn stacked>
                                <v-badge
                                    :content="totalSheepCount"
                                    inline
                                ></v-badge>
                            Sheeps
                            </v-btn>
                            <v-btn stacked>
                                <v-badge
                                    :content="totalCamelCount"
                                    inline
                                ></v-badge>
                            Camels
                            </v-btn>
                        </v-toolbar>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <data-table :tableData="tableData" @pageChanged="pageChanged">
                <template v-slot:[`item.Void`]="{ item }">
                    <v-btn icon="mdi-cancel" size="x-small" color="error" :disabled="item.status === 'Void'"
                        class="my-1" @click="voidHideDetail(item.hideDetailId)" />
                </template>
            </data-table>
        </v-col>
    </v-row>
    <v-dialog v-model="showFilterDialog" width="500" title="Filters">
        <v-card>
            <v-card-title>
                Filters
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete label="Teams" v-model="filters.organizationId" :items="orgs" item-title="name"
                            item-value="organizationId" />
                        <v-row>
                            <v-col class="d-flex">
                                <v-btn @click="clearFilters" color="grey" class="mr-2">Clear</v-btn>
                                <v-btn @click="applyFilters">Filter</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script setup>
import { reactive, onMounted, inject, ref, computed } from 'vue';
import { getAll, updateStatus } from '@/services/hideCollectionService';
import * as utService from '@/services/utilitiesService';
import { useRouter } from 'vue-router'

const router = useRouter()
const loader = inject("loader");
const snackbar = inject("snackBar");
const confirmDialog = inject("confirmDialog");

const pageContext = reactive({
    search: "",
    pageNumber: 1,
    pageSize: 20,
    organizationId: 0
});
const filters = reactive({
    organizationId: null,
});
const tableData = reactive({
    headers: [
        { title: "Division", key: "organization.name" },
        { title: "ITS ID", key: "itsId" },
        { title: "Full Name", key: "fullName" },
        { title: "Email", key: "email" },
        { title: "Mobile", key: "mobile" },
        { title: "Sheep", key: "sheepCount" },
        { title: "Goat", key: "goatCount" },
        { title: "Cow", key: "cowCount" },
        { title: "Camel", key: "camelCount" },
        { title: "Created By", key: "createdBy" },
        { title: "Void", key: "Void" },
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});

const showFilterDialog = ref(false);
const orgs = ref([]);

function navigateHideForm() {
    router.push(`/hide-detail`);
}

async function getData() {
    loader.show();

    let result = await getAll(pageContext)
        .catch(error => console.log(error))
        .finally(() => {
            loader.hide();
        });

    if (result) {
        tableData.data = result.items;
        tableData.paginationContext = {
            pageSize: pageContext.pageSize,
            currentPage: result.currentPage,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
            hasNext: result.hasNext,
            hasPrevious: result.hasPrevious,
        };
    }
}

async function voidHideDetail(id) {
    var isVoid = await confirmDialog.confirm("Void Hide detail", `Are you sure you want to void this record? Please confirm.`, "Yes", "No", true);

    if (!isVoid)
        return;

    loader.show();
    try {
        await updateStatus(id);
        await getData();
        snackbar.success('Collection record is now void');
    } catch (error) {
        snackbar.error(error.message);
    } finally {
        loader.hide();
    }
}

async function pageChanged(pageNo) {
    pageContext.pageNumber = pageNo;
    await getData();
}

async function applyFilters() {
    pageContext.organizationId = filters.organizationId ?? 0;
    pageContext.pageNumber = 1;

    await getData();

    showFilterDialog.value = !showFilterDialog.value;
}

async function clearFilters() {
    filters.organizationId = null;

    pageContext.organizationId = filters.organizationId ?? 0;
    pageContext.pageNumber = 1;

    await getData();
    showFilterDialog.value = !showFilterDialog.value;
}

const totalSheepCount = computed(() => {
    return tableData.data.filter(item => item.status === "Active").reduce((sum, item) => sum + item.sheepCount, 0);
});

const totalGoatCount = computed(() => {
    return tableData.data.filter(item => item.status === "Active").reduce((sum, item) => sum + item.goatCount, 0);
});

const totalCowCount = computed(() => {
    return tableData.data.filter(item => item.status === "Active").reduce((sum, item) => sum + item.cowCount, 0);
});

const totalCamelCount = computed(() => {
    return tableData.data.filter(item => item.status === "Active").reduce((sum, item) => sum + item.camelCount, 0);
});
onMounted(async () => {
    orgs.value = await utService.getAllOrganizations(5)
        .catch(error => console.log(error));
    await getData();
});
</script>