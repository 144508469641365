<template>
  <v-row>
    <v-col>
      <v-text-field label="Search" @update:modelValue="ev => filterCustomers(ev)" />
    </v-col>
  </v-row>
  <template v-for="customer in filteredCustomers" :key="customer.customerId">
    <div class="detail">
      <h4 class="mb-4 pa-4 pb-0">({{ customer.customerId }}) {{ customer.companyName }} - {{ customer.contactName }}
      </h4>
      <v-form @submit.prevent="saveDetail(customer.customerId)">
        <v-table density="compact" class="mb-2">
          <thead>
            <tr>
              <th>Sheep</th>
              <th>Goat</th>
              <th>Cow</th>
              <th>Camel</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-text-field :value="getPrice(customer.customerId, 'sheep')"
                  @update:modelValue="ev => updatePrice(customer.customerId, 'sheep', ev)" class="mt-2" hide-details />
              </td>
              <td>
                <v-text-field :value="getPrice(customer.customerId, 'goat')"
                  @update:modelValue="ev => updatePrice(customer.customerId, 'goat', ev)" class="ml-2 mt-2"
                  hide-details />
              </td>
              <td>
                <v-text-field :value="getPrice(customer.customerId, 'cow')"
                  @update:modelValue="ev => updatePrice(customer.customerId, 'cow', ev)" class="ml-2 mt-2"
                  hide-details />
              </td>
              <td>
                <v-text-field :value="getPrice(customer.customerId, 'camel')"
                  @update:modelValue="ev => updatePrice(customer.customerId, 'camel', ev)" class="ml-2 mt-2"
                  hide-details />
              </td>
              <td>
                <v-btn class="ml-2" type="submit">Save</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-form>
    </div>
  </template>

  <!-- <template v-for="detail in tenderDetailData" :key="detail.customerId">
    <div class="detail">
      <h4 class="mb-4">({{ detail.customerId }}) {{ detail.companyName }} - {{ detail.customerName }}</h4>
      <v-form @submit.prevent="">
        <v-row>
          <template v-for="item in detail.items" :key="item.itemId">
            <v-col>
              <v-text-field :label="item.itemName" v-model="item.price" hide-details />
            </v-col>
          </template>
          <v-col md="2">
            <v-btn type="submit" @click="() => saveDetail(detail)">Save</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </template> -->
</template>

<script setup>
import { ref, onMounted, inject, defineProps } from "vue";
import * as tcService from "@/services/tenderCustomerService";
import * as tdService from "@/services/tenderDetailService";

const loader = inject("loader");
const snackbar = inject("snackBar");

const props = defineProps(["tenderId"]);

const tenderDetail = ref([]);
const customers = ref([]);
const filteredCustomers = ref([]);

function filterCustomers(ev) {
  if (ev) {
    filteredCustomers.value = [];
    filteredCustomers.value.push(...customers.value.filter(x => x.customerId == ev || x.companyName?.toUpperCase().includes(ev.toUpperCase()) || x.contactName?.toUpperCase().includes(ev.toUpperCase())));
  }
  else {
    filteredCustomers.value = [];
    filteredCustomers.value.push(...customers.value);
  }
}

function getPrice(customerId, type) {
  var detail = tenderDetail.value.find(x => x.customerId == customerId);

  if (!detail)
    return 0;

  return parseFloat(detail[type]);
}

function updatePrice(customerId, type, ev) {
  var detail = tenderDetail.value.find(x => x.customerId == customerId);

  if (detail)
    detail[type] = parseFloat(ev ?? 0);
  else {
    var tender = { tenderDetailId: 0, tenderId: props.tenderId, customerId: customerId, sheep: 0, goat: 0, cow: 0, camel: 0 };
    tender[type] = parseFloat(ev ?? 0);
    tenderDetail.value.push(tender);
  }
}

async function saveDetail(customerId) {
  var detail = tenderDetail.value.find(x => x.customerId == customerId);

  loader.show();

  let result = await tdService.add(props.tenderId, detail)
    .catch(ex => snackbar.error(ex));

  if (result)
    snackbar.success("Prices have been saved successfully!")

  loader.hide();
}

onMounted(async () => {
  var promises = [tcService.getAll(), tdService.getByTenderId(props.tenderId)];

  loader.show();

  const [getCustomers, getTenderDetail] = await Promise.all(promises);

  customers.value = getCustomers;
  filteredCustomers.value = getCustomers;
  tenderDetail.value = getTenderDetail;

  console.log(tenderDetail.value);

  loader.hide();

  // customers.value.forEach((cust) => {
  //   var obj = {
  //     tenderId: props.tenderId,
  //     customerId: cust.customerId,
  //     customerName: cust.contactName,
  //     items: [],
  //   };

  //   items.value.forEach((item) => {
  //     var tender = tenderDetail.value.find(
  //       (x) =>
  //         x.tenderId == props.tenderId &&
  //         x.customerId == cust.customerId &&
  //         x.itemId == item.itemId
  //     );

  //     obj.items.push({
  //       tenderDetailId: tender?.tenderDetailId ?? 0,
  //       itemId: item.itemId,
  //       itemName: item.itemName,
  //       price: tender?.price ?? 0,
  //     });
  //   });

  //   tenderDetailData.value.push(obj);
  // });
});
</script>

<style>
.detail {
  border-radius: 5px;
  border: 1px Solid #d6d6d6;
  margin-bottom: 10px;
}
</style>
