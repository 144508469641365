<template>
  <v-card>
    <v-card-text class="d-flex justify-space-between">
      <v-btn href="/tender" size="small">Dashboard</v-btn>
      <v-btn @click="dlgCustomer.show()" size="small">Add Customer</v-btn>
    </v-card-text>
  </v-card>
  <data-table class="mt-2" :tableData="tableData" disable-pagination>
    <template v-slot:[`item.Actions`]="{ item }">
      <v-btn size="x-small" @click="() => editData(item)" icon="mdi-pencil"></v-btn>
      <v-btn size="x-small" @click="() => printForm(item)" class="ml-2 my-1" icon="mdi-printer"></v-btn>
    </template>
  </data-table>

  <simple-dialog ref="dlgCustomer" title="New Customer" width="786" hide-confirm-button hide-cancel-button>
    <template v-slot:content>
      <AddTenderCustomerView @onSave="onNewCustomer" @onCancel="dlgCustomer.hide()" />
    </template>
  </simple-dialog>

  <simple-dialog ref="dlgEditCustomer" title="Edit Customer" width="786" hide-confirm-button hide-cancel-button>
    <template v-slot:content>
      <EditTenderCustomerView :tenderCustomerId="tenderCustomerId" @onSave="onEditCustomer"
        @onCancel="dlgEditCustomer.hide()" />
    </template>
  </simple-dialog>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from "vue";
import * as tcService from "@/services/tenderCustomerService";
import AddTenderCustomerView from "./AddTenderCustomerView.vue";
import EditTenderCustomerView from "./EditTenderCustomerView.vue";
import { useRoute } from 'vue-router';

const loader = inject("loader");
const snackbar = inject("snackBar");

const tableData = reactive({
  headers: [
    { title: "S. No", key: "SNo" },
    { title: "Name", key: "contactName" },
    { title: "Company", key: "companyName" },
    { title: "Mobile", key: "mobile" },
    { title: "Phone", key: "phone" },
    { title: "Email", key: "email" },
    { title: "Address", key: "address" },
    { title: "Actions", key: "Actions" },
  ],
  data: [],
  // itemsPerPage: 15,
  // totalItems: 0,
});

const route = useRoute();

const dlgCustomer = ref(null);
const dlgEditCustomer = ref(null);
const tenderId = route.params.tenderId;
const tenderCustomerId = ref(null);

async function getData() {
  loader.show();

  let result = await tcService.getAll()
    .catch(ex => snackbar.error(ex));

  if (result)
    tableData.data = result;

  loader.hide();
}

async function onNewCustomer() {
  dlgCustomer.value.hide();
  await getData();
}

async function onEditCustomer() {
  dlgEditCustomer.value.hide();
  await getData();
}

function editData(customer) {
  tenderCustomerId.value = customer.customerId;
  dlgEditCustomer.value.show();
}

async function printForm(customer) {
  console.log(customer);

  loader.show();
  var form_data = await tcService.letter(tenderId, customer.customerId)
    .finally(() => {
      loader.hide();
    });

  console.log(form_data);

  var form_template = "";

  fetch("/reports/customer_form.html")
    .then((response) => response.text())
    .then((data) => {
      form_template = data;

      form_template = form_template.replaceAll("#FORMNO", `R\\${form_data.hijriYear}\\${customer.customerId}`);
      form_template = form_template.replaceAll("#COMPANYNAME", `${customer.companyName}`);
      form_template = form_template.replaceAll("#CONTACTPERSON", `${customer.contactName}`);
      form_template = form_template.replaceAll("#CONTACTNO", `${customer.mobile} - ${customer.phone}`);
      form_template = form_template.replaceAll("#ADDRESS", `${customer.address}`);
      form_template = form_template.replaceAll("#DEPOSIT", `PKR ${form_data.tenderAmount}/-`);

      var newWindow = window.open("about:blank", "_blank");
      newWindow.document.write(form_template);
      newWindow.document.close();
    });
}

onMounted(async () => {
  await getData();
});
</script>
