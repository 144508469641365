<template>
  <v-data-table-server density="compact" class="elevation-2 rounded pt-2 pb-4" :headers="tableData.headers"
    :items="tableData.data" :items-length="totalItems" no-data-text="No Records Available" v-bind="$attrs">
    <template v-for="(index, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <template v-slot:[`item.SNo`]="{ index }">
      {{ (index + 1) + (disablePagination ? 0 : (tableData.paginationContext.pageSize *
        (tableData.paginationContext.currentPage - 1))) }}
    </template>
    <template v-slot:bottom>
      <template v-if="!disablePagination">
        <v-pagination v-model="pageNumber" color="primary" :length="tableData.paginationContext.totalPages"
          @update:model-value="pageChanged" rounded="circle" />
      </template>
    </template>
  </v-data-table-server>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  disablePagination: { type: Boolean, required: false },
  tableData: { required: true },
});

console.log(props.tableData);

const pageNumber = ref(1);
const totalItems = ref(0);

const emit = defineEmits(["pageChanged"]);

function pageChanged() {
  emit("pageChanged", pageNumber.value);
}

onMounted(() => {
  // console.log(props.disablePagination);

  if (props.disablePagination)
    totalItems.value = props.tableData.data.length;

  // console.log(totalItems.value);
});

// console.log(props.tableData.data, props.tableData.headers);
</script>
