<template>
    <v-btn v-print>Print QR Code</v-btn>
    <div class="d-flex flex-column align-center">
        <h2>Burhani Guards Pakistan</h2>
        <h3>{{ attendance.value.description }}</h3>
        <h3 class="mb-5">{{ moment(attendance.value.reportingTime).format('DD-MMM-yyyy') }}</h3>
        <qrcode-vue :value="attendance.value.attendanceId.toString()" :level="L" :size="qrSize" />
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import moment from 'moment';
import QrcodeVue from 'qrcode.vue'
import { useDisplay } from 'vuetify';
// import html2pdf from 'html2pdf.js';

defineProps(['attendance']);

const { mobile } = useDisplay();
const qrSize = ref(500);

const vPrint = {
    mounted: () => print,
}

onMounted(() => {
    if (mobile.value)
        qrSize.value = window.innerWidth - 75;
});
</script>