<template>
    <div class="d-flex flex-column" v-if="'title' in tabudaatForm">
        <v-card>
            <v-card-text>
                <h6 class="text-h6 font-weight-bold">
                    {{ tabudaatForm.title }} <br />
                    <small>
                        <span class="text-red">
                            From: {{ moment(tabudaatForm.startDate).format("D-MMM-yyyy") }}
                            To: {{ moment(tabudaatForm.endDate).format("D-MMM-yyyy") }}
                        </span>
                    </small>
                </h6>
                <h6 class="text-subtitle-1" v-html="tabudaatForm.description"></h6>
            </v-card-text>
        </v-card>
        <v-card class="mt-3">
            <v-card-text class="d-flex flex-column">
                <template v-for="item in tabudaatItems" :key="item.tabudaatItemId">
                    <div>
                        <div class="text-subtitle-2 font-weight-bold">{{ item.description }}</div>
                        <v-text-field type="number" v-model="detail[item.columnName]" />
                    </div>
                </template>
            </v-card-text>
        </v-card>
        <v-card class="mt-3" v-if="tabudaatForm.isQuranKhatam">
            <v-card-text>
                <v-form @submit.prevent="saveSipara">
                    <div class="d-flex align-center">
                        <v-autocomplete label="Select Sipara" v-model="selectedSipara" :items="quranSiparas"
                            @update:menu="getAvailableSiparas" :loading="loadingSipara" item-title="siparaId"
                            return-object prefix="Sipara" hide-details />
                        <v-btn prepend-icon="mdi-plus" class="ml-3" type="submit">Add</v-btn>
                    </div>
                </v-form>
                <v-divider class="my-3" />
                <v-list density="compact">
                    <v-list-item density="compact" v-for="quran in tabudaatQuranDetail" :key="quran.tabudaatQuranDetailId" :title="`Sipara ${quran.siparaId}`" />
                </v-list>
            </v-card-text>
        </v-card>
        <v-card class="mt-3">
            <v-card-text class="d-flex justify-end">
                <v-btn @click="saveData">Save</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import * as tabudaatService from '@/services/tabudaatService';
import moment from 'moment';

const route = useRoute();
const loader = inject("loader");
const snackbar = inject("snackBar");

const tabudaatId = route.params.id;
const tabudaatForm = ref({});
const tabudaatItems = ref([]);
const tabudaatDetail = ref([]);
const tabudaatQuranDetail = ref([]);

const loadingSipara = ref(false);
const quranSiparas = ref([]);
const selectedSipara = ref(null);

const detail = ref([]);

async function getData() {
    loader.show();

    try {
        let result = await tabudaatService.getById(tabudaatId);

        if (result)
            tabudaatForm.value = result;
    }
    catch (error) {
        console.log(error);
        snackbar.error(error);
    }

    loader.hide();
}

async function getAvailableSiparas() {
    quranSiparas.value = [];
    
    loadingSipara.value = true;
    quranSiparas.value = await tabudaatService.getAvailableSiparasByFormId(tabudaatId);
    loadingSipara.value = false;
}

async function getSiparas() {
    loadingSipara.value = true;
    tabudaatQuranDetail.value = await tabudaatService.getSiparasByFormId(tabudaatId);
    loadingSipara.value = false;
}

async function saveSipara() {
    loader.show();

    try {
        await tabudaatService.addSipara(tabudaatId, selectedSipara.value);
        snackbar.success("Your sipara has been submitted successfully");
        await getSiparas();
    }
    catch (error) {
        console.log(error);
        snackbar.error(error);
    }

    selectedSipara.value = null;

    loader.hide();
}

async function saveData() {
    loader.show();

    try {
        var formData = [];

        Object.keys(detail.value).forEach(key => {
            var tabDetail = {
                tabudaatName: key,
                amalCount: detail.value[key],
            };

            formData.push(tabDetail);
        });

        await tabudaatService.addDetail(tabudaatId, formData);
        snackbar.success("Your details have been submitted successfully");
    }
    catch (error) {
        console.log(error);
        snackbar.error(error);
    }

    loader.hide();
}

onMounted(async () => {
    await getData();

    loader.show();

    try {
        tabudaatItems.value = await tabudaatService.getItemsByFormId(tabudaatId);
        tabudaatDetail.value = await tabudaatService.getDetailByFormId(tabudaatId);
        await getSiparas();

        detail.value = tabudaatItems.value.reduce((acc, obj) => {
            acc[obj.columnName] = tabudaatDetail.value.find(x => x.tabudaatName == obj.columnName)?.amalCount ?? 0;
            return acc;
        }, {});
    }
    catch (error) {
        console.log(error);
        snackbar.error(error);
    }

    loader.hide();
});

</script>