<template>
  <v-container>
    <v-form @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <v-autocomplete label="Member" v-model="form.itsId" :items="members" item-title="fullName" item-value="itsId"
            hide-details>
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :prepend-avatar="item?.raw?.pictureInIts" :title="item?.raw?.fullName"
                :subtitle="item?.raw?.itsId"></v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="form.requiredUpliftment" :items="upliftments" label="Required Upliftment" required />
        </v-col>
      </v-row>


      <v-row v-show="!!form.requiredUpliftment">
        <v-col>
          <v-text-field v-model="form.totalContribution" label="Total Amount Needed As Loan (PKR)" type="number" required />
        </v-col>
      </v-row>
      <v-row v-show="!!form.requiredUpliftment">
        <v-col>
          <v-textarea v-model="form.proposedRepaymentPlan" label="Proposed Repayment Plan, you can details like number of cheques per month" />
        </v-col>
      </v-row>
      <v-row v-show="!!form.requiredUpliftment">
      <v-col>
        <v-text-field v-model="form.currentEmployerOrBusiness" label="What amount you need as Inayat? (PKR)" required />
      </v-col>
      </v-row>

       <!-- File Upload Section -->
       <v-row v-show="!!form.requiredUpliftment">
      <v-col>
        <v-file-input v-model="form.scannedCnic" label="Scanned CNIC" prepend-icon="mdi-paperclip" />
      </v-col>
      <v-col>
        <v-file-input v-model="form.supportingDocs" label="Any Supporting Docs you want to upload" prepend-icon="mdi-paperclip" />
      </v-col>
    </v-row>


      <v-row v-show="!!form.requiredUpliftment">
        <v-col>
          <v-textarea v-model="form.remarks" label="Any Comments/Remarks/Details you want to add" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn type="submit" color="primary">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <data-table :tableData="tableData">
          <template v-slot:[`item.Actions`]="{ index }">
            <!-- View Button -->
            <v-btn icon="mdi-eye" size="small" @click="viewEntry(index)" class="mr-2"></v-btn>
            
            <!-- Delete Button -->
            <v-btn icon="mdi-delete" size="small" @click="deleteEntry(index)"></v-btn>
        </template>
        </data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert type="error" icon="mdi-alert-circle" title="Error" :text="`Unable to open form: ${errorMessage}`"
          class="my-2" v-if="errorMessage != null && errorMessage.length > 0" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
/* eslint-disable vue/valid-v-slot */

import { getTeamReponses, deleteById, submitResponse } from '@/services/memberUpliftmentFormService';
import { ref, reactive, onMounted, inject } from 'vue';
import { getAll } from '@/services/memberService';
import { useRouter } from 'vue-router';
const form = ref({
  itsId: '',
  requiredUpliftment: '',
  selfContribution: 0,
  divisionContribution: 0,
  centralContribution: 0,
  outsideContribution: 0,
  totalContribution: 0,
  remarks: '',
  proposedRepaymentPlan: '',
  scannedCnic: null,
  inayatAmount:0,
  supportingDocs: null
});

const pageContext = reactive({
  pageNumber: 1,
  pageSize: 1000,
  totalItems: 0,
  totalPages: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  pageIndex: 0,
  search: "",
  itsId: 0,
  organizationId: 0,
  sectionId: 0,
  statusId: 0
});
const errorMessage = ref("");
const loader = inject("loader");

const members = ref([]);
const router = useRouter();
const upliftments = ref(['House Hold Item', 'Repairing', 'Medical', 'New House']); // Replace with your upliftments
const tableData = reactive({
  headers: [
    { title: 'Member', key: 'fullName' },
    { title: 'Required Upliftment', key: 'requiredUpliftment' },
    { title: 'Total Amount Needed (PKR)', key: 'totalContribution' },
    { title: 'Proposed Repayment Plan', key: 'proposedRepaymentPlan' },
    { title: 'Remarks', key: 'remarks' },
    { title: 'Actions', key: 'Actions' }
  ],
  data: [],
  itemsPerPage: 15,
  totalItems: 0,
  paginationContext: {},
});


const loadMembers = async () => {
  let result = await getAll(pageContext)
    .catch(error => console.log(error))
    .finally(() => {
      loader.hide();
    });

  console.log(result);

  members.value = result?.members;
};

const loadEntries = async () => {
  loader.show();
  errorMessage.value = "";

  let response = await getTeamReponses()
    .catch(error => errorMessage.value = error)
    .finally(async () => {
      loader.hide();
    });

  if (response != null && !errorMessage.value && response.length > 0) {
    tableData.data = response.map(entry => ({
      ...entry,
      fullName: entry.member?.fullName,
    }));

  }
};

const viewEntry = (index) => {
    const entry = tableData.data[index];
    router.push(`/upliftment-form-detailed/${entry.id}`);
};
  
const submitForm = async () => {
  if (!form.value.requiredUpliftment || !form.value.itsId) {
    return;
  }
  let c = confirm("Are you sure you want to submit the entry?")
  if (!c) {
    return;
  }
  loader.show();
  errorMessage.value = "";
  const formData = new FormData();
  Object.keys(form.value).forEach(key => {
    if (key === 'scannedCnic' || key === 'supportingDocs') {
      if (form.value[key]) {
        formData.append('files', form.value[key]);
      }
    } else {
      formData.append(key, form.value[key]);
    }
  });

  await submitResponse(form.value)
    .catch(error => errorMessage.value = error)
    .finally(async () => {
      loader.hide();
    });
  form.value = {
    itsId: '',
    requiredUpliftment: '',
    selfContribution: 0,
    divisionContribution: 0,
    centralContribution: 0,
    outsideContribution: 0,
    totalContribution: 0,
    remarks: '',
    proposedRepaymentPlan: '',
    scannedCnic: null,
    inayatAmount:0,
    supportingDocs: null
  };

  await loadEntries();

};

const deleteEntry = async (index) => {
  let c = confirm("Are you sure you want to delete?")
  if (!c) {
    return;
  }
  loader.show();
  const entry = tableData.data[index];
  await deleteById(entry.id)
    .finally(async () => {
      await loadEntries();
      loader.hide();
    });
};


onMounted(async () => {
  await loadMembers();
  await loadEntries();
});
</script>