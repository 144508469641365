<template>
    <v-carousel show-arrows="hover" continuous hide-delimiter-background height="400px" progress="primary"
        v-model="slide" v-if="transfers.length > 0">
        <v-carousel-item v-for="(transfer, i) in transfers" :key="i">
            <div class="d-flex flex-column pa-5 align-center justify-center">
                <v-img :src="transfer.memberPictureInUniform" width="200px" height="200px" />
                <div>{{ transfer.itsId }}</div>
                <div class="text-subtitle-1">{{ transfer.memberFullName }}</div>
                <div class="text-subtitle-1" v-if="transfer.fromOrganizationName">
                    From: <b>{{ transfer.fromOrganizationName }} </b> To: <b>{{ transfer.toOrganizationName }}</b>
                </div>
                <div class="text-subtitle-1" v-if="transfer.fromSectionDescription">
                    From: <b>{{ transfer.fromSectionDescription }} </b> To: <b>{{ transfer.toSectionDescription }}</b>
                </div>
                <v-row class="w-100">
                    <v-col>
                        <v-btn size="small" block class="mt-5" @click="approve(transfer.requestId)">Approve</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="red-darken-2" size="small" block class="mt-5"
                            @click="delete_transfer(transfer)">Delete</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-carousel-item>
    </v-carousel>
    <div class="text-subtitle-1 font-weight-bold text-center" v-else>
        No Transfer Requests Pending
    </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue';
import * as mrService from '@/services/memberRequestService';

const loader = inject("loader");
const snackbar = inject("snackBar");
const confirmDialog = inject("confirmDialog");

const transfers = ref([]);
const slide = ref(0);

async function getData() {
    loader.show();

    var result = await mrService.transfer_getPending()
        .catch(ex => snackbar.error(ex));

    loader.hide();

    if (result) {
        transfers.value = result;
    }
}

async function approve(id) {
    loader.show();

    await mrService.transfer_approve(id)
        .then(async () => {
            snackbar.success("Transfer has been approved and completed");
            await getData();
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function delete_transfer(transfer) {
    const is_delete = await confirmDialog.confirm("Delete Request", `Are you sure you want to delete transfer request of: ${transfer.memberFullName}? Please confirm`, "Yes", "No", true);
    
    if (!is_delete)
    return;

    loader.show();

    await mrService.transfer_delete(transfer.requestId)
        .then(async () => {
            snackbar.success("Transfer request has been deleted");
            await getData();
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

onMounted(async () => {
    await getData();
});

</script>