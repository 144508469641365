import { apiCall } from "@/setup/webApi";

export const getAll = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();

  return new Promise((resolve, reject) => {
    apiCall("get", `Member/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Member/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getProfileById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Member/profile/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const add = (member) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `Member/`, member)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
export const update = (its, member) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `Member/${its}`, member)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getMemberSectionCount = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Member/memberSectionCount`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};