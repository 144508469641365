<template>
  <v-container>
    <v-row class="mb-4">
      <v-col>
        <h2>Upliftment Form Response of {{ response?.memberDetail?.fullName }}</h2>
      </v-col>
    </v-row>

    <!-- Picture Cards -->
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-img :src="response?.memberDetail?.pictureInITS" height="250px" contain class="light-shadow"></v-img>
          <v-card-title class="text-center">Picture in ITS</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-img :src="response?.memberDetail?.pictureInUniform" height="250px" contain class="light-shadow"></v-img>
          <v-card-title class="text-center">Picture in Uniform</v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <!-- Main Details -->
    <v-row class="mt-5">
      <v-col>
        <v-subheader><h3>Main Details</h3></v-subheader>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <br/>
        <br/>
        <!-- Main Fields -->
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Required Upliftment:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response.requiredUpliftment" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>     
         
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Total Amount Needed:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response.totalContribution" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>        
          </v-col>

          <v-row>
      <v-col cols="12" md="6">
        <!-- Text Fields -->
        <v-row class="mb-2">
          <!-- Proposed Repayment Plan -->
          <v-col cols="4">
            <label class="font-weight-bold">Amount Needed as Inayat:</label>
          </v-col>
          <v-col cols="8">
            <v-text-field :value="response.inayatAmount" readonly outlined solo flat hide-details></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <!-- File Fields -->
        <!-- Scanned CNIC -->
        <v-row class="mb-2">
          <v-col cols="12">
            <label class="font-weight-bold"><v-btn
                    :href="response.scannedCnic"
                    target="_blank"
                >Open Uploaded Scanned CNIC
            </v-btn></label>
          </v-col>
        </v-row>


        <!-- Scanned Copy of ITS Reference -->

        <v-row class="mb-2">
          <v-col cols="12">
            <label class="font-weight-bold"><v-btn
                    :href="response.supportingDocs"
                    target="_blank"
                >Open Uploaded Supporting Docs
            </v-btn></label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
          <v-col cols="12" md="6">
            
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Remarks:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response.remarks" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Member Details -->
    <v-row class="mt-5">
      <v-col>
        <v-subheader><h3>Member Details</h3></v-subheader>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <br/>
        <br/>
        <!-- Member Fields -->
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Full Name:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.fullName" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Team:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.team" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Section:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.section" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Rank:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.rank" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Status:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.status" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Mobile No:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.mobileNo" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Email:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.email" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Date of Birth:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.dateOfBirth" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Date of Joining:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.dateOfJoining" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Age:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.age" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Joining Years:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.joiningYears" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Financial Contribution:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.financialContribution" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="4">
                    <label class="font-weight-bold">Total Attendance:</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field :value="response?.memberDetail?.totalAttendance" readonly outlined solo flat hide-details></v-text-field>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Error Alert -->
    <v-row class="mt-4">
      <v-col>
        <v-alert type="error" icon="mdi-alert-circle" title="Error" :text="`Unable to open form: ${errorMessage}`"
          class="my-2" v-if="errorMessage != null && errorMessage.length > 0" />
      </v-col>
    </v-row>
  </v-container>
</template>

  
  <script setup>

    import { getSingleResponse } from '@/services/memberUpliftmentFormService';

  import { ref, onMounted, inject} from 'vue';
  import { useRouter } from 'vue-router';
  
  const response = ref({});
  const loader = inject("loader");
  const router = useRouter();
  const id = router.currentRoute.value.params.id;
  const errorMessage = ref("");

  const loadDetails = async () => {
    loader.show();
    errorMessage.value = "";
  
    let resp = await getSingleResponse(id)
      .catch(error => errorMessage.value = error)
      .finally(async () => {
        loader.hide();
      });
  
    if (resp != null && !errorMessage.value) {
      response.value = resp;
      console.log(response.value)
    }
  };
  
  onMounted(async () => {
    await loadDetails();
  });
    </script>