import { apiCall } from "@/setup/webApi";

export const getActive = () => {
    return new Promise((resolve, reject) => {
        apiCall("get", "Miqaat/active")
          .then((response) => {
            resolve(response);
          })
          .catch((ex) => {
            reject(ex);
          });
      });
};

export const procurement_getItems = () => {
  return new Promise((resolve, reject) => {
      apiCall("get", "Miqaat/procurement/items")
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
};

export const procurement_getRights = (miqaatId) => {
  return new Promise((resolve, reject) => {
      apiCall("get", `Miqaat/${miqaatId}/procurement/rights`)
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
};

export const procurement_getDetail = (miqaatId, departmentId) => {
  return new Promise((resolve, reject) => {
      apiCall("get", `Miqaat/${miqaatId}/procurement/detail/${departmentId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
};

export const procurement_saveDetail = (miqaatId, departmentId, data) => {
  return new Promise((resolve, reject) => {
      apiCall("post", `Miqaat/${miqaatId}/procurement/detail/${departmentId}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
};