import { apiCall } from "@/setup/webApi";

export const getRoles = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `AccessControl/roles/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addRole = (role) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `AccessControl/roles`, role)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const updateRole = (id, role) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `AccessControl/roles/${id}`, role)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteRole = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `AccessControl/roles/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getModulesForRoles = (roleId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `AccessControl/roles/${roleId}/modules`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addPermissionForRoles = (roleId, permission) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `AccessControl/roles/${roleId}/permission`, permission)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getUsersForRoles = (roleId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `AccessControl/roles/${roleId}/users`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addUserForRoles = (roleId, user) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `AccessControl/roles/${roleId}/users`, user)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteUserForRoles = (roleId, itsId) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `AccessControl/roles/${roleId}/users/${itsId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const data_getUsersAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `AccessControl/data/users/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const data_getPermissions = (itsId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `AccessControl/data/permissions/${itsId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const data_updatePermissions = (itsId, formData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `AccessControl/data/permissions/${itsId}`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const data_deleteUser = (itsId) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `AccessControl/data/users/${itsId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};