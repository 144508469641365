<template>
    <v-app>
        <v-container fluid class="fill-height">
            <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                    <v-card :class="mobilePadding">
                        <v-card-text>
                            <v-form validate-on="login" @submit.prevent="onLoginClick">
                                <v-row justify="center">
                                    <v-col>
                                        <v-img :src="require(`@/assets/bgt_logo.jpg`)" height="80px"></v-img>
                                        <h5 class="text-h5 mb-3 text-center font-weight-bold">Burhani Guards Pakistan</h5>
                                        <h5 class="text-h6 mb-5 text-center">LOGIN</h5>
                                        <v-text-field 
                                            ref="txtUsername"
                                            label="Its ID"
                                            v-model="loginForm.itsId"
                                            :rules="[(v) => !!v || 'Please enter its number']" />
                                        <v-text-field 
                                            label="Password"
                                            type="password"
                                            v-model="loginForm.password"
                                            :rules="[(v) => !!v || 'Please enter password']" />
                                        <v-btn type="submit" block :loading="isLoading" :disabled="isLoading">Login</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <snack-bar />
    </v-app>
</template>

<script setup>
import { ref, reactive, onMounted, inject, computed } from 'vue';
import { useDisplay } from 'vuetify';
import { useRouter } from "vue-router";
import { login } from "@/services/authService";

const snackbar = inject("snackBar");
const router = useRouter();
const txtUsername = ref(null);
const loginForm = reactive({
    itsId: "",
    password: "",
});
const isLoading = ref(false);
const { mobile } = useDisplay();

const mobilePadding = computed(() => {
    return mobile.value ? "pa-4" : "pa-10";
});

onMounted(() => {
    txtUsername.value.focus();
});

async function onLoginClick(event) {
    if (isLoading.value) return;

    const result = await event;
    if (!result.valid) return;

    isLoading.value = true;
    const isLogin = await login(loginForm)
        .catch((ex) => {
            snackbar.error(ex);
            console.log(ex);
        })
        .finally(() => (isLoading.value = false));

    if (isLogin) {
        snackbar.success("Logged in successfully!");
        setTimeout(() => {
            router.push("/home");
        }, 1000);
    }
}

</script>