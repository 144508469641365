<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <v-row class="mb-5">
                        <v-col>
                            <v-btn type="button" color="info" @click="navigateHideList()">Hide Collection
                                List</v-btn>
                        </v-col>
                    </v-row>
                    <v-form @submit.prevent="addHideDetail">
                        <v-row>
                            <v-col>
                                <v-text-field class="mb-2" label="ITS ID" type="number" v-model="hideDetail.itsId"
                                    :rules="[rules.required]" />
                                <v-text-field class="mb-2" label="Full Name" v-model="hideDetail.fullName"
                                    :rules="[rules.required]" />
                                <v-text-field class="mb-2" label="Email" v-model="hideDetail.email" />
                                <v-text-field class="mb-2" label="Mobile" v-model="hideDetail.mobile" />
                                <v-text-field class="mb-2" label="Sheep Count" v-model="hideDetail.sheepCount"
                                    type="number" />
                                <v-text-field class="mb-2" label="Goat Count" v-model="hideDetail.goatCount"
                                    type="number" />
                                <v-text-field class="mb-2" label="Cow Count" v-model="hideDetail.cowCount"
                                    type="number" />
                                <v-text-field class="mb-2" label="Camel Count" v-model="hideDetail.camelCount"
                                    type="number" />
                            </v-col>
                        </v-row>
                        <v-btn type="submit" :disabled="!isFormValid" color="primary" class="mt-3">Save</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
            <v-alert type="error" v-if="errorMessage">
                {{ errorMessage }}
            </v-alert>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, reactive, onMounted, computed, inject } from 'vue';
import { addDetail } from '@/services/hideCollectionService';
import { tender_getActive } from '@/services/tenderService'
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/setup/stores/authStore';

const snackbar = inject("snackBar");
const loader = inject("loader");

const authStore = useAuthStore();
const router = useRouter();

const hideDetail = reactive({
    itsId: 'null',
    organizationId: authStore.user.organizationId,
    fullName: '',
    email: '',
    mobile: '',
    sheepCount: 0,
    goatCount: 0,
    cowCount: 0,
    camelCount: 0,
    Status: '',
});

const tender = ref({});
const errorMessage = ref("");

const rules = {
    required: value => !!value || 'Required.',
};

const isFormValid = computed(() => {
    return hideDetail.itsId && hideDetail.fullName && (
        hideDetail.sheepCount > 0 ||
        hideDetail.goatCount > 0 ||
        hideDetail.cowCount > 0 ||
        hideDetail.camelCount > 0
    );
});

function navigateHideList() {
    router.push(`/hide-detail-list`);
}

async function getData() {
    loader.show();
    try {
        tender.value = await tender_getActive();
    } catch (error) {
        console.log(error)
        errorMessage.value = error.message;
    }
    loader.hide();
}

async function addHideDetail() {
    try {
        loader.show();

        hideDetail.tenderId = tender.value?.tenderId || 0;
        hideDetail.camelCount =  hideDetail.camelCount || 0;
        hideDetail.goatCount =  hideDetail.goatCount || 0;
        hideDetail.sheepCount =  hideDetail.sheepCount || 0;
        hideDetail.cowCount =  hideDetail.cowCount || 0;
        const createdDetail = await addDetail(hideDetail);

        if (createdDetail) {
            snackbar.success("Hide collection record saved");
            navigateHideList();

            // Object.keys(hideDetail).forEach(key => {

            //     if (["sheepCount", "goatCount", "cowCount", "camelCount"].includes(key)) {
            //         hideDetail[key] = 0;
            //     } else {
            //         hideDetail[key] = '';
            //     }
            // });
        }

    } catch (error) {
        snackbar.error(error);
        errorMessage.value = error.message;
    }

    loader.hide();
}

onMounted(async () => {
    await getData();
});
</script>