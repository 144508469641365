<template>
    <v-row v-if="'fullName' in member">
        <v-col cols="12">
            <v-card>
                <v-card-text class="d-flex pa-0">
                    <v-img :src="member.pictureInUniform" height="150px" max-width="160px" cover />
                    <v-divider vertical />
                    <div class="pa-4 flex-grow-1">
                        <h5 class="text-h6 font-weight-bold">{{ member.fullName }}</h5>
                        <h5 class="text-h6">{{ member.itsId }}</h5>
                        <h5 class="text-h6">{{ member.rank.description }}</h5>
                        <h5 class="text-h6">{{ member.organization.name }} - {{ member.section.description }}</h5>
                    </div>
                    <v-divider vertical />
                    <div class="pa-4 d-flex flex-column justify-center" style="width:175px;">
                        <h5 class="text-h4 font-weight-bold text-center text-green">0</h5>
                        <h5 class="text-h6 text-center">Pts</h5>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <!-- <v-col cols="6">
            <v-card>
                <v-card-text class="d-flex">
                    <div class="flex-grow-0 ma-2">
                        <v-img height="125px" width="125px" />
                        <h6 class="text-subtitle-1 text-center">Polite</h6>
                    </div>
                    <div class="flex-grow-0 ma-2">
                        <v-img height="125px" width="125px" />
                        <h6 class="text-subtitle-1 text-center">Best Member</h6>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card>
                <v-card-text class="d-flex">
                    <v-list>
                        <v-list-item title="NKD" subtitle="2022-1444H" />
                        <v-list-item title="MKD" subtitle="2022-1444H" />
                        <v-list-item title="NDI" subtitle="2022-1444H" />
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col> -->
        <v-col cols="12" md="4">
            <v-card class="mb-3">
                <v-card-text>
                    <v-autocomplete :items="ranks" item-title="description" item-value="generalID" v-model="member.rankId" hide-details />
                </v-card-text>
            </v-card>

            <v-card>
                <v-card>
                    <v-card-title>Personal</v-card-title>
                    <v-card-text>
                        <v-text-field label="Mobile No" v-model="member.mobileNo" />
                        <v-text-field label="Email" v-model="member.email" />
                        <v-text-field label="CNIC" v-model="member.cnic" />
                        <v-text-field label="Home Address" v-model="member.homeAddress" />
                        <div class="d-flex mb-3">
                            <VueDatePicker v-model="member.dateOfBirth" :enable-time-picker="false" auto-apply
                                format="dd/MMM/yyyy" :max-date="new Date()" :text-input="{ format: 'dd/MMM/yyyy' }"
                                model-type="dd-MMM-yyyy" placeholder="dd/MMM/yyyy" />
                            <div class="px-3 py-2 text-subtitle-1" style="min-width: 100px;">{{ member.age }} Years</div>
                        </div>
                        <v-text-field label="Marital Status" v-model="member.maritalStatus" />
                        <v-text-field label="House Status" v-model="member.houseStatus" />
                        <v-text-field label="Blood Group" v-model="member.bloodGroup" />
                        <v-row>
                            <v-col>
                                <v-text-field label="Weight" v-model="member.weight" />
                            </v-col>
                            <v-col>
                                <v-text-field label="Height" v-model="member.height" />
                            </v-col>
                        </v-row>
                        <v-text-field label="Other Health Issues" v-model="member.otherHealthIssues" />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
        <v-col cols="12" md="4">
            <v-card>
                <v-card>
                    <v-card-title>Professional</v-card-title>
                    <v-card-text>
                        <v-text-field label="Education Level" v-model="member.educationLevel" />
                        <v-text-field label="Degree" v-model="member.degree" />
                        <v-text-field label="Occupation" v-model="member.occupation" />
                        <v-text-field label="Specialization" v-model="member.occupationField" />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
        <v-col cols="12" md="4">
            <v-card>
                <v-card>
                    <v-card-title>Idara</v-card-title>
                    <v-card-text>
                        <div class="d-flex mb-3">
                            <VueDatePicker v-model="member.dateOfJoining" :enable-time-picker="false" auto-apply
                                format="dd/MMM/yyyy" :max-date="new Date()" :text-input="{ format: 'dd/MMM/yyyy' }"
                                model-type="dd-MMM-yyyy" placeholder="dd/MMM/yyyy" />
                            <div class="px-3 py-2 text-subtitle-1" style="min-width: 100px;">{{ moment().diff(member.dateOfJoining, 'years') }}
                                Years</div>
                        </div>
                        <v-text-field label="Hifz Status" v-model="member.quranHifz" />
                        <v-text-field label="Deeni Taleem" v-model="member.deeniTaalim" />
                        <v-text-field label="Other Organizations" v-model="member.otherOrganizations" />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card>
                <v-card-text align="end">
                    <v-btn @click="saveData(itsId)">Save</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import { useRoute } from "vue-router";
import * as memService from '@/services/memberService';
import * as utService from '@/services/utilitiesService';
import moment from 'moment';


const route = useRoute();
const loader = inject("loader");
const snackbar = inject("snackBar");

const itsId = route.params.its;
const member = ref({});
const ranks = ref([]);

async function getData(its) {
    loader.show();

    let result = await memService.getProfileById(its)
        .finally(() => {
            loader.hide();
        });

    member.value = result;
    member.value.dateOfBirth = moment(member.value.dateOfBirth).format("DD-MMM-YYYY");
    member.value.dateOfJoining = moment(member.value.dateOfJoining).format("DD-MMM-YYYY");
}

async function saveData(its) {
    loader.show();

    var formData = { ...member.value };
    formData.organization = null;
    formData.section = null;
    formData.rank = null;
    formData.status = null;

    await memService.update(its, formData)
        .catch(ex => snackbar.error(ex));

    snackbar.success("Data has been saved successfully");
    loader.hide()
}

onMounted(async () => {
    ranks.value = await utService.getGeneralsById(9);
    await getData(itsId);
});
</script>