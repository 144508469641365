<template>
    <v-row>
        <v-col>
            <v-autocomplete label="Status" v-model="statusRequest.toStatusId" :items="statuses" item-title="description"
                item-value="generalID" />
        </v-col>
    </v-row>
    <v-row>
        <v-col align="end">
            <v-btn variant="text" class="mr-2" color="black" @click="emit('onClose')">Cancel</v-btn>
            <v-btn @click="save">Save</v-btn>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import * as utService from '@/services/utilitiesService';
import * as mrService from '@/services/memberRequestService';

const loader = inject("loader");
const snackbar = inject("snackBar");

const emit = defineEmits('onSave', 'onClose');

const props = defineProps({
    member: {
        required: true,
    },
});

const statuses = ref([]);

const statusRequest = ref({
    itsId: props.member.itsId,
    toStatusId: null,
});

async function save() {
    loader.show();

    await mrService.status_add(statusRequest.value)
        .then(() => {
            snackbar.success("Status request has been generated. Please wait for the approval.");
            emit('onSave');
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

onMounted(async () => {
    var promises = [
        utService.getGeneralsById(6),
    ]

    loader.show();

    const [getStatus] = await Promise.all(promises);
    statuses.value = getStatus;

    loader.hide();
});

</script>