<template>
    <v-row>
        <v-col>
            <data-table :tableData="tableData" :disablePagination="true">
                <template v-slot:[`item.startDate`]="{ item }">
                    {{ moment(item.raw.startDate).format("D-MMM-yyyy") }}
                </template>
                <template v-slot:[`item.endDate`]="{ item }">
                    {{ moment(item.raw.endDate).format("D-MMM-yyyy") }}
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                    <v-btn icon="mdi-magnify" size="small" :href="`/sharaf-forms/${item.raw.sharafId}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>
<script setup>

import { reactive, onMounted, inject } from 'vue';
import { getActive } from '@/services/sharafService';
import moment from 'moment';
// import { useRouter } from "vue-router";

const loader = inject("loader");
//const router = useRouter();

const tableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Title", key: "sharafTitle" },
        { title: "StartDate", key: "startDate" },
        { title: "EndDate", key: "endDate" },
        { title: "Actions", key: "Actions" },
    ],
    data: [],
});

async function getData() {
    loader.show();

    let result = await getActive()
        .finally(() => {
            loader.hide();
        });

    console.log(result);

    tableData.data = result;
}

onMounted(async () => {
    await getData();
});

</script>