<template>
    <v-row>
        <v-col>
            <data-table :tableData="tableData" @pageChanged="pageChanged">
                <template v-slot:[`item.itsId`]="{ item }">
                    <div class="d-flex flex-column align-center pa-2">
                        <v-img :src="item.raw.pictureInUniform" width="60px" height="60px"
                            style="border:1px Solid #c6c6c6; border-radius: 4px;" cover />
                        {{ item.raw.itsId }}
                    </div>
                </template>
                <template v-slot:[`item.section`]="{ item }">
                    {{ item.raw.section.description }} ({{ item.raw.organization.name }})
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip>{{ item.raw.status.description }}</v-chip>
                </template>
                <template v-slot:[`item.Actions`]="{ item }" v-if="!adminView">
                    <v-btn color="red"  size="small" v-if="isRegistered(item.raw)" @click="deleteEntry(item.raw)">Unregister</v-btn>
                    <v-btn size="small" v-if="!isRegistered(item.raw)" @click="registerMember(item.raw)">Register</v-btn>
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script setup>

import {  reactive, onBeforeUpdate, inject, defineProps, defineEmits} from 'vue';
import { addCampMember,deleteRegistration } from '@/services/campService';

// import { useRouter } from "vue-router";
const loader = inject("loader");
const snackbar = inject("snackBar");


const emit = defineEmits(['pageChanged', 'updateData']);
// const router = useRouter();



const tableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Its", key: "itsId" },
        { title: "Name", key: "fullName" },
        { title: "Rank", key: "rank.description" },
        { title: "Section", key: "section" },
        { title: "Mobile", key: "mobileNo" },
        { title: "Status", key: "status" },
        { title: "Actions", key: "Actions" },
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});



const camp = reactive({
    "id": null,
    "title": null,
    "campMembers": []
})
const props = defineProps(['members','listContext','campData','adminView']);


function isRegistered(member) {
    if(camp.campMembers && camp.campMembers.find(x=>x.itsId == member.itsId)){
        return true;
    }
    return false;
}
async function registerMember(member) {
    loader.show();
    let postData = {
        campId: camp.id,
        itsId:member.itsId,
        organizationId: member.organizationId
    }
    await addCampMember(postData)
        .catch(error => {
            snackbar.error(error);
            console.log(error);
        })
        .finally(async () => {
            emit('updateData');
            snackbar.success(`${member.fullName} registered successfully.`);
            loader.hide();
        });
   
}
const deleteEntry = async (member) => {
  let c = confirm(`Are you sure you want to Unregister?`)
  if (!c) {
    return;
  }
  loader.show();
  
  await deleteRegistration(member.itsId)
    .finally(async () => {
        emit('updateData');
        loader.hide();
    });
};
async function pageChanged(pageNo) {
    emit('pageChanged',pageNo);
}



onBeforeUpdate(() => {
    tableData.data = props.members;
    tableData.paginationContext = props.listContext;
    camp.campMembers = props.campData.campMembers;
    camp.id = props.campData.id;
    if(props.adminView){
        // Remove the "Actions" key from the data
        const actionsHeaderIndex = tableData.headers.findIndex(header => header.title === "Actions");
        if (actionsHeaderIndex !== -1) {
            tableData.headers.splice(actionsHeaderIndex, 1);
    
    }
    }
    
   
});


</script>