import { apiCall } from "@/setup/webApi";



export const getCampMember = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();

  return new Promise((resolve, reject) => {
    apiCall("get", `Camp/CampMember/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getCampById = (id,params) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Camp/${id}`, params)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getRegisteredMembers = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();
  return new Promise((resolve, reject) => {
    apiCall("get", `Camp/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addCampMember = (postData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `Camp/CampMember`, postData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getCampMemberById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Camp/CampMember/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteRegistration = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `Camp/CampMember/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};