<template>
    <v-app>
        <v-container fluid class="fill-height">
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-card :class="mobilePadding">
                        <v-card-text>
                            <v-img :src="require(`@/assets/bgt_logo.jpg`)" height="175px"></v-img>
                            <div class="text-center pa-10">
                                <h1 class="mb-5">Burhani Guards Pakistan</h1>
                                <h4 class="mb-5 text-justify">
                                    Burhani Guards Pakistan is a unique platform for khidmat guzars of all age groups. Youth get ready with discipline of performing khidmat of mumineen and get uplifted according to their age and performances.
                                </h4>
                                <v-btn href="https://www.its52.com/Login.aspx?OneLogin=BGTPAK">Click Here To Login</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script setup>
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

const mobilePadding = computed(() => {
    return mobile.value ? "pa-4" : "pa-10";
});
</script>