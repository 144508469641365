<template>
    <v-form v-model="formValidity" @submit.prevent="save">
        <v-row>
            <v-col md="6" cols="12">
                <v-card>
                    <v-card-title>Personal</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col md="6" cols="12">
                                <v-autocomplete label="Team" :items="orgs" item-title="name" item-value="organizationId"
                                    v-model="member.organizationId" :rules="validations.team" hide-details />
                            </v-col>
                            <v-col md="6" cols="12">
                                <v-autocomplete label="Section" :items="sections" item-title="description"
                                    item-value="generalID" v-model="member.sectionId" :rules="validations.section"
                                    hide-details />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="4" cols="12">
                                <v-text-field label="Its No" v-model="member.itsId" :rules="validations.itsId"
                                    hide-details />
                            </v-col>
                            <v-col md="4" cols="6">
                                <v-text-field label="Hof Its No" v-model="member.hofId" :rules="validations.hofId"
                                    hide-details />
                            </v-col>
                            <v-col md="4" cols="6">
                                <v-text-field label="SF No" v-model="member.sfNo" :rules="validations.sfNo" />
                            </v-col>
                        </v-row>
                        <v-text-field label="Full Name" v-model="member.fullName" :rules="validations.fullName" />
                        <v-text-field label="Mobile" v-model="member.mobileNo" />
                        <v-text-field label="Email" v-model="member.email" />
                        <v-text-field label="CNIC No" v-model="member.cnic" :rules="validations.cnic" />
                        <v-text-field label="Home Address" v-model="member.homeAddress" />
                        <date-picker label="Date Of Birth" date-format="dd-MMM-yyyy" v-model="member.dateOfBirth" :rules="validations.dob" />
                        <!-- <v-text-field label="Date Of Birth" v-model="member.dateOfBirth" /> -->
                        <v-autocomplete label="Marital Status" v-model="member.maritalStatus"
                            :items="['Single', 'Engaged', 'Married', 'Divorced', 'Widowed']" />
                        <v-autocomplete label="House Status" :items="['Rented', 'Owned', 'Goodwill']" />
                        <v-autocomplete label="Blood Group" v-model="member.bloodGroup"
                            :items="['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']" />
                        <v-row>
                            <v-col>
                                <v-text-field label="Weight" v-model="member.weight" />
                            </v-col>
                            <v-col>
                                <v-text-field label="Height" v-model="member.height" />
                            </v-col>
                        </v-row>
                        <v-text-field label="Other Health Issues" v-model="member.otherHealthIssues" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="6" cols="12">
                <v-card>
                    <v-card-title>Professional & Khidmat</v-card-title>
                    <v-card-text>
                        <v-text-field label="Education Level"
                            :items="['Unattended School', 'Primary', 'Secondary', 'Higher Secondary', 'Under Graduate', 'Graduate', 'Post Graduate', 'Doctorate']" />
                        <v-text-field label="Degree" />
                        <v-text-field label="Occupation" />
                        <v-text-field label="Specialization" />
                        <v-autocomplete label="Hifz Status"
                            :items="['N/A', 'Surah al-Balad', 'Surah al-Inshiqaq', 'Juz Amma', 'Sanah Ula', 'Sanah Saniyah', 'Sanah Salesah', 'Hafiz']" />
                        <v-text-field label="Deeni Taleem" />
                        <v-text-field label="Other Organizations" />
                    </v-card-text>
                </v-card>
                <!-- <v-card class="mt-3">
                    <v-card-title>
                        <div class="d-flex justify-space-between">
                            Titles
                            <v-btn size="small">Add</v-btn>
                        </div>
                    </v-card-title>
                    <v-card-text>

                    </v-card-text>
                </v-card> -->
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text align="end">
                        <v-btn type="submit">Save</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script setup>
import { ref, inject, onMounted } from "vue";
import * as utService from '@/services/utilitiesService';
import * as memberService from '@/services/memberService';

var loader = inject("loader");
var snackbar = inject("snackBar");

const member = ref({
    organizationId: null,
    sectionId: null,
    itsId: null,
    hofId: null,
    sfNo: null,
    fullName: null,
    mobileNo: null,
    email: null,
    homeAddress: null,
    dateOfBirth: null,
    maritalStatus: null,
    houseStatus: null,
    bloodGroup: null,
    weight: null,
    height: null,
    cnic: null,
    otherHealthIssues: null,
});
const orgs = ref([]);
const sections = ref([]);
const formValidity = ref(false);

const validations = {
    itsId: [
        val => {
            if (val)
                return true;

            return "Its number is required";
        }
    ],
    hofId: [
        val => {
            if (val)
                return true;

            return "HOF Its number is required";
        }
    ],
    sfNo: [
        val => {
            if (val)
                return true;

            return "SF number is required";
        }
    ],
    fullName: [
        val => {
            if (val)
                return true;

            return "Full name is required";
        }
    ],
    cnic: [
        val => {
            if (val)
                return true;

            return "CNIC no is required";
        }
    ],
    dob: [
        val => {
            if (val)
                return true;

            return "Date of birth is required";
        }
    ],
    team: [
        val => {
            if (val)
                return true;

            return "Team is required";
        }
    ],
    section: [
        val => {
            if (val)
                return true;

            return "Section is required";
        }
    ],
};

async function save() {
    if (!formValidity.value)
        return;

    loader.show();

    const result = await memberService.add(member.value)
        .catch(ex => {
            snackbar.error(ex);
        });

    console.log(result);

    if (result) {
        snackbar.success("Member data has been saved successfully");
        setTimeout(() => window.location.href = "/members", 1500);
    }

    loader.hide();
}

onMounted(async () => {
    var promises = [
        utService.getOrganizations(5),
        utService.getSections()
    ];

    loader.show();

    const [getOrgs, getSections] = await Promise.all(promises);

    orgs.value = getOrgs;
    sections.value = getSections;

    loader.hide();

    member.value.organizationId = orgs.value[0].organizationId;
});
</script>