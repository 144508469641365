<template>
    <v-row>
        <v-col>
            <template  v-if="'miqaatName' in miqaat && miqaatRights">
                <v-card>
                    <v-card-text>
                        <div class="text-h5 font-weight-bold">{{ miqaat.miqaatName }}</div>
                        <div class="text-h6 font-weight-bold">Procurement Requirements Form</div>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3">
                    <v-card-text>
                        <v-autocomplete label="Department" v-model="departmentId" :items="miqaatDepartments"
                            item-title="description" item-value="generalID" @update:modelValue="getDetail" />
                    </v-card-text>
                    <v-card-text class="pa-0" v-if="departmentId">
                        <v-table density="compact" fixed-header :height="tableHeight" id="tblItems">
                            <thead>
                                <tr>
                                    <th Width="70px">S. No</th>
                                    <th>Item Name</th>
                                    <th Width="150px">Count</th>
                                </tr>
                                <tr>
                                    <th colspan="3">
                                        <div class="d-flex justify-center">
                                            <v-btn size="small" class="my-2" block @click="addOtherItem">Add Item</v-btn>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item, i) in miqaatItems" :key="item.itemId">
                                    <tr :class="rowColor(i)">
                                        <td>{{ i + 1 }}</td>
                                        <td>
                                            <template v-if="item.itemId != null">
                                                {{ item.itemName }}
                                            </template>
                                            <template v-else>
                                                <v-text-field :value="item.itemName"
                                                    @update:modelValue="ev => updateName(item, ev)" hide-details />
                                            </template>
                                        </td>
                                        <td>
                                            <v-text-field class="my-1 bg-white" type="number" :value="getQty(item)"
                                                @update:modelValue="ev => updateQty(item, ev)" hide-details />
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3" v-if="departmentId">
                    <v-card-text align="end">
                        <v-btn @click="saveDetail">Save</v-btn>
                    </v-card-text>
                </v-card>
            </template>
            <template v-else>
                <v-card>
                    <v-card-text class="text-h6 font-weight-bold text-center py-5">
                        No active form is available
                    </v-card-text>
                </v-card>
            </template>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, inject, onMounted, computed } from 'vue';
import * as miqaatService from '@/services/miqaatService';
import * as utService from '@/services/utilitiesService';
// import moment from 'moment';
// import { useRoute, useRouter } from 'vue-router';
// import { useAuthStore } from "@/setup/stores/authStore";

const loader = inject("loader");
const snackbar = inject("snackBar");
// const route = useRoute();
// const router = useRouter();
// const authStore = useAuthStore();

const miqaat = ref({});
const miqaatRights = ref(null);
const miqaatId = ref(null);
const departmentId = ref(null);

const miqaatDepartments = ref([]);
const miqaatItems = ref([]);
const miqaatDetail = ref([]);

// let miqaatDetail = {
//     itemId: null,
//     itemName: null,
//     itemCount: 0,
// };

const tableHeight = computed(() => {
    const height = window.innerHeight;
    return `${height - 380}px`;
});

function rowColor(ix) {
    const isEven = ix % 2;

    return isEven ? 'bg-grey-lighten-3' : '';
}

async function getMiqaat() {
    loader.show();

    const result = await miqaatService.getActive()
        .catch(ex => snackbar.error(ex));

    if (result) {
        miqaat.value = result;
        miqaatId.value = result.miqaatId;
    }

    loader.hide();
}

async function getDetail() {
    loader.show();

    const result = await miqaatService.procurement_getDetail(miqaatId.value, departmentId.value)
        .catch(ex => snackbar.error(ex));

    if (result) {
        miqaatDetail.value = result;

        miqaatItems.value = miqaatItems.value.filter(x => x.itemId != null);

        miqaatDetail.value.filter(x => x.itemId == null).forEach(x => {
            miqaatItems.value.push({ itemId: null, itemName: x.itemName, category: "new" });
        })
    }

    loader.hide();
}

function getQty(item) {
    var detail = miqaatDetail.value.find(x => item.itemId != null ? x.itemId == item.itemId : x.itemName == item.itemName);
    
    if (detail)
        return detail.count;
    else
        return null
}

function updateName(item, name) {
    var detail = miqaatDetail.value.find(x => x.itemName == item.itemName);
    var is_exists = miqaatDetail.value.find(x => x.itemName == name);

    console.log(name, detail, is_exists);

    if (is_exists) {
        snackbar.error(`This item: ${name} already exists`);
    }
    else {
        item.itemName = name;
        detail.itemName = name;
    }
}

function updateQty(item, count) {
    var detail = miqaatDetail.value.find(x => item.itemId != null ? x.itemId == item.itemId : x.itemName == item.itemName);
    console.log(detail, count);

    if (detail)
        detail.count = parseInt(count);
    else
        miqaatDetail.value.push({ detailId: null, itemId: item.itemId, itemName: item.itemName, count: parseInt(count) });
}

function addOtherItem() {
    miqaatItems.value.push({ itemId: null, itemName: "", category: "new" });
    miqaatDetail.value.push({ detailId: null, itemId: null, itemName: "", count: null });

    let tbl = document.getElementById("tblItems");
    let rowPos = tbl.querySelector("tr:last-child").getBoundingClientRect();

    tbl.scrollTo(0, rowPos.top);
}

async function saveDetail() {
    loader.show();

    var formData = {
        detail: miqaatDetail.value,
    }

    const result = await miqaatService.procurement_saveDetail(miqaatId.value, departmentId.value, formData)
        .catch(ex => snackbar.error(ex));

    loader.hide();

    if (result) {
        getDetail();
        snackbar.success("Form has been submitted succesfully");
    }

}

onMounted(async () => {
    await getMiqaat();

    if ('miqaatName' in miqaat.value) {
        loader.show();

        const promises = [miqaatService.procurement_getRights(miqaatId.value), utService.getGeneralsById(43), miqaatService.procurement_getItems()];

        const [rights, departments, items] = await Promise.all(promises);

        miqaatRights.value = rights;
        miqaatDepartments.value = departments;
        miqaatItems.value = items;

        loader.hide();

        console.log(miqaatRights.value);
    }
});

</script>

<style>
#tblItems tbody tr:hover {
    background-color: blanchedalmond !important;
}
</style>