import { apiCall } from "@/setup/webApi";

export const getModules = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Module/authenticated`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};