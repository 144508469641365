// import "vuetify/styles";
import "@/styles/main.scss";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labs from 'vuetify/labs/components'
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { fa } from "vuetify/iconsets/fa";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: "#f6f6f6",
    surface: "#FFFFFF",
    primary: "#347438",
    "primary-darken-1": "#1f4622",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export const useCreatedVuetify = createVuetify({
  // styles: {
  //   configFile: 'src/styles/settings.scss',
  // },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  components: {
    ...components,
    ...labs,
    VueDatePicker 
  },
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  defaults: {
    VAppBar: {
    },
    VDatePicker : {
    },
    VTimePicker : {
    },
    VBtn: {
      color: 'primary',
    },
    VTextField: {
      variant: 'outlined',
      density: 'compact',
    },
    VAutocomplete: {
      variant: 'outlined',
      density: 'compact',
    },
    VSelect: {
      variant: 'outlined',
      density: 'compact',
      "hide-details": true,
    },
    VDataTableServer: {
      density: 'compact',
    },
    VCheckbox: {
      color: 'primary',
      'hide-details': true,
    }, 
    
  }
});
