<template>
    <v-text-field :model-value="dateVal" v-bind="$attrs" readonly>
        <v-menu v-model="showPopup" activator="parent" :close-on-content-click="false">
            <vue-date-picker inline time-picker-inline v-model="dateVal" @update:model-value="updateValue"
                :format="dateFormat" :model-type="dateFormat" clearable :enable-time-picker="showTime" />
        </v-menu>
    </v-text-field>
</template>

<script setup>
//import moment from "moment";
import { ref, defineProps, defineEmits, onMounted } from "vue";

const showPopup = ref(false);

const props = defineProps({
    dateFormat: {
        type: String,
        default: "dd-MMM-yyyy hh:mm aa",
    },
    showTime: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: String,
        default: null,// moment().format("DD-MMM-YYYY hh:mm a"),
    },
});

const emit = defineEmits(["update:modelValue"])

const dateVal = ref(null);

function updateValue(val) {
    showPopup.value = false;
    emit("update:modelValue", val);
}

onMounted(() => {
    if (props.modelValue)
        dateVal.value = props.modelValue;
});
</script>