import SnackBar from "./snackBar.vue";

export default {
  install: (app) => {
    const emitter = app.config.globalProperties.$emitter; //app.inject("emitter");

    app.component("SnackBar", SnackBar);

    function show(message, color = "success", timeout = 1500) {
      emitter.emit("showSnackbar", [message, color, timeout]);
    }
    function success(message, timeout = 1500) {
      emitter.emit("showSnackbar", [message, "success", timeout]);
    }
    function error(message, timeout = 1500) {
      emitter.emit("showSnackbar", [message, "error", timeout]);
    }

    app.provide("snackBar", { show, success, error });
  },
};
