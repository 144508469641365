<template>
    <v-row>
        <v-col>
            <v-card class="mb-4">
                <v-card-text class="d-flex">
                    <v-text-field label="Search" v-model="pageContext.search" hideDetails class="mr-2"
                        @keydown.enter="getData" />
                </v-card-text>
            </v-card>
            <v-card>
    <v-tabs
      v-model="tab"
      color="primary"
      align-tabs="center"
    >
      <v-tab  @click="filterSection(21)" :value="1">Guards &nbsp;<span style="font-weight: bold;">{{ regCount(21) }}/{{ memberTotalCount(21) }}</span></v-tab>
      <v-tab @click="filterSection(23)" :value="2">Scouts  &nbsp;<span style="font-weight: bold;" >{{ regCount(23) }}/{{ memberTotalCount(23) }}</span></v-tab>
      <v-tab @click="filterSection(24)" :value="3">Shaheen &nbsp;<span style="font-weight: bold;">{{ regCount(24)}} /{{ memberTotalCount(24) }}</span></v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item
        v-for="n in 3"
        :key="n"
        :value="n"
      >
        <v-container fluid>
          <v-row>
            <v-col>
            <MemberListView :members="members" :adminView="false" :campData="campData" :listContext="listContext" @updateData="updateCampData" @pageChanged="pageChanged" ></MemberListView>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
  </v-card>
          
        </v-col>
    </v-row>
    <v-dialog v-model="showFilterDialog" width="500" title="Filters">
        <v-card>
            <v-card-title>
                Filters
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete label="Teams" v-model="filters.organizationId" :items="orgs" item-title="name"
                            item-value="organizationId" />
                        <v-autocomplete label="Sections" v-model="filters.sectionId" :items="sections" item-title="description" item-value="generalID" />
                        <v-autocomplete label="Status" v-model="filters.statusId" :items="memberstatus" item-title="description" item-value="generalID" />
                        <v-row>
                            <v-col class="d-flex">
                                <v-btn @click="clearFilters" color="grey" class="mr-2">Clear</v-btn>
                                <v-btn @click="applyFilters">Filter</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>

import { ref, reactive, onMounted, inject } from 'vue';
import { getAll, getMemberSectionCount } from '@/services/memberService';
import { getCampById } from '@/services/campService';
import { getOrganizations, getSections, getMemberStatus } from '@/services/utilitiesService';
import MemberListView from '../CampRegistrationForm/MemberListView.vue';
// import { useRouter } from "vue-router";
const loader = inject("loader");


// const router = useRouter();

const showFilterDialog = ref(false);

const pageContext = reactive({
    pageNumber: 1,
    pageSize: 20,
    totalItems: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    pageIndex: 0,
    search: "",
    itsId: 0,
    organizationId: 0,
    sectionId: 21,
    statusId: 0
});


const filters = reactive({
    organizationId: null,
    sectionId: null,
    statusId: null,
});


const orgs = ref([]);
const sections = ref([]);
const memberstatus = ref([]);
const memberSectionCount = ref([]);
const members = ref([]);
const listContext = ref([]);
const campData = ref([]);
const tab =  ref(null);

async function getData() {
    loader.show();

    let result = await getAll(pageContext)
        .catch(error => console.log(error))
        .finally(async () => {
            loader.hide();
        }); 
        
        members.value = result.members;
        listContext.value  = result.listContext;

}


async function applyFilters() {
    pageContext.organizationId = filters.organizationId ?? 0;
    pageContext.sectionId = filters.sectionId ?? 0;
    pageContext.statusId = filters.statusId ?? 0;

    await getData();

    showFilterDialog.value = !showFilterDialog.value;
}

async function filterSection(sectionId) {
    pageContext.sectionId =  sectionId;
    await getData();
}

async function clearFilters() {
    filters.organizationId = null;
    filters.sectionId = null;
    filters.statusId = null;

    pageContext.organizationId = filters.organizationId ?? 0;
    pageContext.sectionId = filters.sectionId ?? 0;
    pageContext.statusId = filters.statusId ?? 0;

    await getData();

    showFilterDialog.value = !showFilterDialog.value;
}
async function pageChanged(pageNo) {
    pageContext.pageNumber = pageNo;
    await getData();
}
async function updateCampData(){
    campData.value = await getCampById(1)
   .catch(error => console.log(error))
}
function memberTotalCount(sectionId){
    return (memberSectionCount.value && memberSectionCount.value.find(x => x.sectionId === sectionId)) ? memberSectionCount.value.find(x => x.sectionId === sectionId).count : 0;
}
function regCount(sectionId){
   return (campData.value.campMemberCount && campData.value.campMemberCount.find(x => x.sectionId === sectionId)) ? campData.value.campMemberCount.find(x => x.sectionId === sectionId).count : 0;
}

onMounted(async () => {
    loader.show();

    orgs.value = await getOrganizations()
        .catch(error => console.log(error));
    sections.value = await getSections()
        .catch(error => console.log(error));
    memberstatus.value = await getMemberStatus()
        .catch(error => console.log(error));
    memberSectionCount.value = await getMemberSectionCount()
        .catch(error => console.log(error));
    campData.value = await getCampById(1)
        .catch(error => console.log(error))
        await getData();
});

</script>