<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text align="end">
          <v-btn size="small" @click="navigateHideDeliveryForm()">Add New</v-btn>
        </v-card-text>
      </v-card>

      <data-table :tableData="tableData" :disablePagination="true" class="mt-3">
        <template v-slot:[`item.dispatchedAt`]="{ item }">
          {{ formatDate(item.dispatchedAt) }}
        </template>
        <template v-slot:[`item.Edit`]="{ item }">
          <v-btn icon="mdi-pencil" size="x-small" color="info" class="my-1"
            @click="editHideDelivery(item.hideDeliveryId)" />
        </template>
      </data-table>
    </v-col>
  </v-row>
</template>

<script setup>
import { reactive, onMounted, inject } from 'vue';
import { getDeliveryDetailByTeam } from '@/services/hideCollectionService';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/setup/stores/authStore';
import moment from 'moment';

const router = useRouter();
const loader = inject("loader");
const snackbar = inject("snackBar");
const authStore = useAuthStore()

const tableData = reactive({
  headers: [
    { title: "Driver Name", key: "driverName" },
    { title: "Car Plate No", key: "carPlateNo" },
    { title: "Sheep Count", key: "sheepCount" },
    { title: "Goat Count", key: "goatCount" },
    { title: "Cow Count", key: "cowCount" },
    { title: "Camel Count", key: "camelCount" },
    { title: "Dispatched By", key: "dispatchedBy" },
    { title: "Dispatched At", key: "dispatchedAt" },
    { title: "Edit", key: "Edit" }
  ],
  data: [],
});

function formatDate(date) {
  return moment(date).format('DD MMM yyyy hh:mm a');
}

function navigateHideDeliveryForm() {
  router.push(`/hide-delivery`);
}

function editHideDelivery(id) {
  router.push(`/hide-delivery/${id}`);
}

async function getData() {
  loader.show();
  try {
    const teamId = authStore.user.organizationId;
    const result = await getDeliveryDetailByTeam(teamId);
    tableData.data = result;
  } catch (error) {
    snackbar.error(error.message);
  } finally {
    loader.hide();
  }
}



onMounted(async () => {
  await getData();
});
</script>