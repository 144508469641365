<template>
    <v-row>
        <v-col>
            <v-card class="mb-3">
                <v-card-text align="end">
                    <v-btn size="small" @click="dlgAttendance.show()">New
                        Event</v-btn>
                </v-card-text>
            </v-card>
            <data-table :tableData="tableData" @pageChanged="pageChanged">
                <template v-slot:[`item.dutyDate`]="{ item }">
                    <span>{{ moment(item.dutyDate).format("D-MMM-yyyy") }} </span>
                </template>
                <template v-slot:[`item.reportingTime`]="{ item }">
                    <span>{{ moment(item.reportingTime).format("hh:mm A") }}</span>
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                    <div class="pa-2">
                        <v-btn icon="mdi-qrcode" size="x-small" class="mr-2" @click="viewQr(item)" />
                        <v-btn icon="mdi-eye-circle-outline" size="x-small" class="mr-2"
                            @click="viewAttendance(item.attendanceId)" />
                        <!-- <v-btn icon="mdi-square-edit-outline" size="x-small" class="mr-2" @click="editAttendance(item)" />
                        <v-btn icon="mdi-trash-can-outline" size="x-small" class="mr-2" @click="deleteAttendance(item)" /> -->
                    </div>
                </template>
            </data-table>
        </v-col>
    </v-row>

    <simple-dialog ref="dlgAttendance" width="500" title="New Attendance" cancelButtonText="Close" @ok="submitForm"
        hide-confirm-button hide-cancel-button>
        <template v-slot:content>
            <AddAttendanceView @onClose="dlgAttendance.hide()" @onSave="onAttendanceSaved(false)" />
        </template>
    </simple-dialog>

    <simple-dialog ref="dlgEditAttendance" width="500" title="Edit Attendance" cancelButtonText="Close" @ok="submitForm"
        hide-confirm-button hide-cancel-button>
        <template v-slot:content>
            <EditAttendanceView :attendanceId="attendanceId" @onClose="dlgEditAttendance.hide()"
                @onSave="onAttendanceSaved(true)" />
        </template>
    </simple-dialog>

    <simple-dialog ref="dlgAttendanceQr" cancelButtonText="Close" :hideConfirmButton="true">
        <template v-slot:content>
            <QrCodeView :attendance="selectedAttendance" />
        </template>
    </simple-dialog>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue';
import * as atService from '@/services/attendanceService';
import { useRouter } from "vue-router";
import moment from 'moment';
import QrCodeView from './QrCodeView.vue';
import AddAttendanceView from './AddAttendanceView.vue';
import EditAttendanceView from './EditAttendanceView.vue';

const loader = inject("loader");
const snackbar = inject("snackBar");
// const confirmDialog = inject("confirmDialog");
const router = useRouter();

var dlgAttendance = ref(null);
var dlgEditAttendance = ref(null);
var dlgAttendanceQr = ref(null);

const attendanceId = ref(0);
const selectedAttendance = reactive({});

const pageContext = reactive({
    search: "",
    pageNumber: 1,
    pageSize: 20,
    organizationId: 0,
    AttendanceTypeId: 0,
    ShowDivisionEvents: false
});

const tableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Team", key: "organization.name" },
        { title: "Duty Date", key: "dutyDate" },
        { title: "Details", key: "description" },
        { title: "Reporting Time", key: "reportingTime" },
        { title: "Actions", key: "Actions" }
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});

async function getData() {
    loader.show();

    let result = await atService.master_getAll(pageContext)
        .catch(error => console.log(error))
        .finally(() => {
            loader.hide();
        });

    if (result) {
        tableData.data = result.items;
        tableData.paginationContext = {
            pageSize: pageContext.pageSize,
            currentPage: result.currentPage,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
            hasNext: result.hasNext,
            hasPrevious: result.hasPrevious,
        };
    }
}

async function onAttendanceSaved(isEdit) {
    snackbar.success(isEdit);

    if (isEdit)
        dlgEditAttendance.value.hide();
    else
        dlgAttendance.value.hide();

    await getData();
}

function viewQr(att) {
    selectedAttendance.value = att;
    dlgAttendanceQr.value.show();
}

function viewAttendance(attendanceId) {
    router.push(`/attendance/${attendanceId}`);
}

// function editAttendance(att) {
//     attendanceId.value = att.attendanceId;
//     dlgEditAttendance.value.show();
// }

// async function deleteAttendance(att) {
//     const isDelete = await confirmDialog.confirm("Delete Attendance", `Are you sure you want to delete this event: ${att.description}? Please confirm.`, 'Yes', 'No', true);
//     if (!isDelete)
//         return;

//     await atService.master_delete(att.attendanceId)
//         .then(() => snackbar.success("Attendance has been deleted successfully"))
//         .catch(error => {
//             snackbar.error(error);
//             console.log(error);
//         })

//     await getData();
//     loader.hide();
// }

async function pageChanged(pageNo) {
    pageContext.pageNumber = pageNo;
    await getData();
}

onMounted(async () => {
    await getData();
});
</script>

<style>
.button-spacing {
    margin: 10px;
}

.v-card {
    overflow: unset !important;
}

.custom-margin-top {
    margin-top: 10px;
}

.custom-margin-bottom {
    margin-bottom: 10px;
}
</style>