<template>
    <v-row>
        <v-col>
            <v-card class="mb-3">
                <v-card-text class="d-flex">
                    <v-row>
                        <v-col md="6" cols="12" class="d-flex">
                            <v-text-field label="Search" v-model="pageContext.search" hideDetails class="mr-2"
                                @keydown.enter="getData" />
                            <v-btn icon="mdi-filter-multiple" size="small"
                                @click="showFilterDialog = !showFilterDialog" />
                        </v-col>
                        <v-col md="6" cols="12" class="d-flex justify-end align-center">
                            <v-btn size="small" href="/members/add">Add Member</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- <v-card class="mb-3">
                <v-card-text align="end">
                    <v-btn size="small" href="/members/add">Add Member</v-btn>
                    <v-btn @click="exportToExcel" size="small">Export To Excel</v-btn>
                </v-card-text>
            </v-card> -->

            <data-table :tableData="tableData" @pageChanged="pageChanged">
                <template v-slot:[`item.itsId`]="{ item }">
                    <div class="d-flex flex-column align-center pa-2">
                        <v-img :src="item.pictureInUniform" width="60px" height="60px"
                            style="border:1px Solid #c6c6c6; border-radius: 4px;" cover />
                        {{ item.itsId }}
                    </div>
                </template>
                <template v-slot:[`item.section`]="{ item }">
                    <div>{{ item.section.description }} ({{ item.organization.name }})</div>
                    <v-btn size="small" class="mt-1" @click="openTransferRequest(item)">Transfer</v-btn>
                    <v-btn size="small" class="mt-1" @click="openStatusRequest(item)">Status</v-btn>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip>{{ item.status.description }}</v-chip>
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" v-bind="props" size="small" variant="icon"></v-btn>
                        </template>
                        <v-list density="compact">
                            <v-list-item title="View" prepend-icon="mdi-magnify" :href="`members/${item.itsId}`"
                                density="compact" />
                            <v-list-item title="Edit" prepend-icon="mdi-pencil" :href="`members/${item.itsId}/edit`"
                                density="compact" v-if="validateAuthOfficers" />
                        </v-list>
                    </v-menu>
                    <!-- <v-btn icon="mdi-magnify" size="small" @click="viewMember(item)" /> -->
                </template>
            </data-table>
        </v-col>
    </v-row>
    <v-dialog v-model="showFilterDialog" width="500" title="Filters">
        <v-card>
            <v-card-title>
                Filters
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete label="Teams" v-model="filters.organizationId" :items="orgs" item-title="name"
                            item-value="organizationId" />
                        <v-autocomplete label="Sections" v-model="filters.sectionId" :items="sections"
                            item-title="description" item-value="generalID" />
                        <v-autocomplete label="Status" v-model="filters.statusId" :items="memberstatus"
                            item-title="description" item-value="generalID" />
                        <v-row>
                            <v-col class="d-flex">
                                <v-btn @click="clearFilters" color="grey" class="mr-2">Clear</v-btn>
                                <v-btn @click="applyFilters">Filter</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showTransferDialog" width="500" title="Transfer">
        <v-card>
            <v-card-title>
                Member Transfer
            </v-card-title>
            <v-card-text>
                <MembersTransferView :member="selectedMember" @onSave="showTransferDialog = false"
                    @onClose="showTransferDialog = false" />
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showStatusDialog" width="500" title="Status">
        <v-card>
            <v-card-title>
                Member Status
            </v-card-title>
            <v-card-text>
                <MembersStatusView :member="selectedMember" @onSave="showStatusDialog = false"
                    @onClose="showStatusDialog = false" />
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script setup>

import { ref, reactive, onMounted, inject, computed } from 'vue';
import { getAll } from '@/services/memberService';
import * as utService from '@/services/utilitiesService';
// import { members } from '@/services/exportService';
// import { saveAs } from 'file-saver';
import MembersTransferView from './MembersTransferView.vue';
import MembersStatusView from './MembersStatusView.vue';
import { useAuthStore } from "@/setup/stores/authStore";

const loader = inject("loader");

const showFilterDialog = ref(false);
const showTransferDialog = ref(false);
const showStatusDialog = ref(false);

const selectedMember = ref({});

const pageContext = reactive({
    search: "",
    pageNumber: 1,
    pageSize: 20,
    organizationId: 0,
    sectionId: 0,
    statusId: 0
});

const tableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Its", key: "itsId" },
        { title: "Name", key: "fullName" },
        { title: "Rank", key: "rank.description" },
        { title: "Section", key: "section" },
        { title: "Mobile", key: "mobileNo" },
        { title: "Status", key: "status" },
        { title: "Actions", key: "Actions" },
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});

const filters = reactive({
    organizationId: null,
    sectionId: null,
    statusId: null,
});
const orgs = ref([]);
const sections = ref([]);
const memberstatus = ref([]);

const authStore = useAuthStore();
const authOfficers = ref([
    40496719,
    40476109,
    40419031,
    40480238,
    40452802,
    40480239,
    40496185,
    40463461,
]);

const validateAuthOfficers = computed(() => {
    if (!!authStore.user && !!authStore.user.itsId)
        return authOfficers.value.includes(authStore.user.itsId);
    else
        return false;
});

async function getData() {
    loader.show();

    let result = await getAll(pageContext)
        .catch(error => console.log(error))
        .finally(() => {
            loader.hide();
        });

    if (result) {
        tableData.data = result.items;
        tableData.paginationContext = {
            pageSize: pageContext.pageSize,
            currentPage: result.currentPage,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
            hasNext: result.hasNext,
            hasPrevious: result.hasPrevious,
        };
    }
}

// async function exportToExcel() {
//     await members(pageContext)
//         .then(response => {
//             const blob = new Blob([response.data]);
//             saveAs(blob, 'MembersList.xlsx');
//         })
//         .catch(error => console.log(error))
//         .finally(() => {
//             loader.hide();
//         });
// }

async function pageChanged(pageNo) {
    pageContext.pageNumber = pageNo;
    await getData();
}

async function applyFilters() {
    pageContext.organizationId = filters.organizationId ?? 0;
    pageContext.sectionId = filters.sectionId ?? 0;
    pageContext.statusId = filters.statusId ?? 0;
    pageContext.pageNumber = 1;

    await getData();

    showFilterDialog.value = !showFilterDialog.value;
}

async function clearFilters() {
    filters.organizationId = null;
    filters.sectionId = null;
    filters.statusId = null;

    pageContext.organizationId = filters.organizationId ?? 0;
    pageContext.sectionId = filters.sectionId ?? 0;
    pageContext.statusId = filters.statusId ?? 0;
    pageContext.pageNumber = 1;

    await getData();

    showFilterDialog.value = !showFilterDialog.value;
}

function openTransferRequest(member) {
    selectedMember.value = member;
    showTransferDialog.value = true;
}

function openStatusRequest(member) {
    selectedMember.value = member;
    showStatusDialog.value = true;
}

onMounted(async () => {
    loader.show();

    orgs.value = await utService.getOrganizations()
        .catch(error => console.log(error));
    sections.value = await utService.getSections()
        .catch(error => console.log(error));
    memberstatus.value = await utService.getMemberStatus()
        .catch(error => console.log(error));

    await getData();
});

</script>