import InputDialog from "./inputDialog.vue";

export default {
  install: (app) => {
    const emitter = app.config.globalProperties.$emitter;

    app.component("InputDialog", InputDialog);

    function input(title, message, confirmText = "Ok", cancelText = "Cancel", isCritical = false) {
      return new Promise((resolve, reject) => {
        emitter.emit("input", { title, message, confirmText, cancelText, isCritical, resolve, reject });
      });
    }

    app.provide("inputDialog", { input });
  },
};
