import { apiCall } from "@/setup/webApi";

export const getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SurveyForm/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getActive = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SurveyForm/active`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SurveyForm/active/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addDetail = (id, answers) => {
  var formData = {
    answers: answers,
  }

  return new Promise((resolve, reject) => {
    apiCall("post", `SurveyForm/detail/${id}`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};