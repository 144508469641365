<template>
    <v-row>
        <v-table class="w-100">
            <thead>
                <tr>
                    <th>Account</th>
                    <th v-for="head in heads" :key="head.expenseHeadId">{{ head.expenseHead }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="account in accounts" :key="account.expenseAccountId">
                    <td>{{ account.accountName }}</td>
                    <td v-for="head in heads" :key="head.expenseHeadId">{{ getSum(account.expenseAccountId, head.expenseHeadId) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-row>
</template>

<script setup>
const props = defineProps(['accounts', 'heads', 'expenses']);

function getSum(expenseAccountId, expenseHeadId) {
    var exp = props.expenses.filter(x => x.expenseAccountId == expenseAccountId && x.expenseHeadId == expenseHeadId);    
    return exp.reduce((n, { amount }) => n + amount, 0);
}

console.log(props);
</script>