<template>
  <v-dialog v-model="showDialog" width="500px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        {{ message }}
        <v-text-field class="mt-2" v-model="answer" />
        <div class="d-flex justify-end mt-5">
          <v-btn variant="text" @click="cancel">
            {{ cancelText }}
          </v-btn>
          <v-btn :color="isCritical ? 'error' : 'primary'" @click="ok">
            {{ confirmText }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, inject } from "vue";

const emitter = inject("emitter");

var resolve = null;
// var reject = null;

const answer = ref("");

const title = ref("");
const message = ref("");
const isCritical = ref(false);
const confirmText = ref("");
const cancelText = ref("");
const showDialog = ref(false);

emitter.on("input", (args) => {
  title.value = args.title;
  message.value = args.message;
  isCritical.value = args.isCritical;
  confirmText.value = args.confirmText;
  cancelText.value = args.cancelText;

  answer.value = "";

  showDialog.value = true;
  resolve = args.resolve;
});

function cancel() {
  showDialog.value = false;
  resolve(null);
}

function ok() {
  showDialog.value = false;
  resolve({
    answer: answer.value,
  });
}
</script>
