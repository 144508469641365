import { apiCall } from "@/setup/webApi";

export const getModules = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/modules`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getOrganizations = (type) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/orgs${(type != null ? `?t=${type}` : '')}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getAllOrganizations = (type) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/orgs/all${(type != null ? `?t=${type}` : '')}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getSections = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/sections`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getMemberStatus = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/memberstatus`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getAttendanceTypes = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/attendancetype`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getGeneralsById = (parentId) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Utilities/generals/${parentId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};