<template>
    <v-form @submit.prevent="saveData">
        <v-row>
            <v-col>
                <!-- <v-text-field type="date" label="Date" v-model="tender.tenderDate" /> -->
                <VueDatePicker v-model="tender.tenderDate" :enable-time-picker="false" auto-apply format="dd/MMM/yyyy"
                    :text-input="{ format: 'dd/MMM/yyyy' }" model-type="dd-MMM-yyyy" placeholder="dd/MMM/yyyy" />
                <v-text-field type="number" label="Hijri Year" v-model="tender.hijriYear" />
                <v-text-field type="number" label="Deposit Amount" v-model="tender.depositAmount" />
                <v-checkbox v-model="tender.isActive" label="Active"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="end">
                <v-btn>Save</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script setup>
import { ref, inject } from "vue";
import * as tenderService from "@/services/tenderService";

const loader = inject("loader");
const snackbar = inject("snackBar");

const tender = ref({
    tenderId: 0,
    tenderDate: "",
    hijriYear: 0,
    depositAmount: 0,
    isActive: false,
});

async function saveData() {
    loader.show();
    const result = await tenderService.tender_add(tender.value)
        .catch(ex => snackbar.error(ex));
    loader.hide();

    console.log(result);
}

</script>