<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-text>
                    <v-btn-toggle v-model="selectedIndex" color="brown" class="mb-5" density="compact"
                        variant="elevated" elevation="2" hide-details>
                        <v-btn>Division-Wise</v-btn>
                        <v-btn>Account-Wise</v-btn>
                    </v-btn-toggle>
                </v-card-text>
            </v-card>

            <v-card class="mt-3 pa-0">
                <v-card-text>
                    <DivWiseExpenses :orgs="orgs" :heads="heads" :expenses="expenses" v-if="selectedIndex == 0" />
                    <AccWiseExpenses :accounts="accounts" :heads="heads" :expenses="expenses" v-if="selectedIndex == 1" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import * as teService from "@/services/tenderExpenseService";
import * as utService from '@/services/utilitiesService';
import { useRoute } from 'vue-router';

import DivWiseExpenses from './Reports/DivWiseExpenses.vue';
import AccWiseExpenses from './Reports/AccWiseExpenses.vue';

const route = useRoute();

const loader = inject("loader");

const selectedIndex = ref(0);

const tenderId = route.params.tenderId;
const accounts = ref([]);
const heads = ref([]);
const orgs = ref([]);
const expenses = ref([]);

onMounted(async () => {
    var promises = [teService.accounts_getAll(), teService.heads_getAll(), utService.getOrganizations(), teService.getAll(tenderId)];

    loader.show();

    const [getAccounts, getHeads, getOrgs, getExpenses] = await Promise.all(promises);

    accounts.value = getAccounts;
    heads.value = getHeads;
    orgs.value = getOrgs;
    expenses.value = getExpenses;

    loader.hide();
});

</script>