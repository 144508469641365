<template>
    <v-app>
        <v-container fluid class="fill-height">
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-img class="rotate" :src="require(`@/assets/bgt_logo.jpg`)" height="175px"></v-img>
                    <div class="text-center pa-10">
                        <h4 class="mb-5 text-justify">
                            Burhani Guards Pakistan is a unique platform for khidmat guzars of all age groups. Youth
                            get ready with discipline of performing khidmat of mumineen and get uplifted according
                            to their age and performances.
                        </h4>
                        <h4>Authenticating, Please Wait...</h4>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <snack-bar />
    </v-app>
</template>

<script setup>
import { reactive, onMounted, inject, computed } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { login } from "@/services/authService";

const router = useRouter();
const route = useRoute();
const snackbar = inject("snackBar");
const loginForm = reactive({
    itsId: "",
    password: "",
});

const id = computed(() => {
    return window.atob(route.query.sID);
});

onMounted(async () => {
    let its = id.value.split(",")[0];
    loginForm.itsId = its;
    loginForm.password = its.slice(-4);

    const isLogin = await login(loginForm)
        .catch((ex) => {
            snackbar.error(ex);
            console.log(ex);
        });

    if (isLogin) {
        setTimeout(() => {
            router.push("/home");
        }, 1000);
    }
});

</script>

<style scoped>
@keyframes rotation {
    from {
        transform: rotateY(180deg);
    }

    to {
        transform: rotateY(0deg);
    }
}

.rotate {
    animation-name: rotation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
</style>