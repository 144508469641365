<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row class="mb-5">
            <v-col>
              <v-btn type="button" color="info" class="mt-3" @click="navigateHideDeliveryList()">Hide Delivery
                List</v-btn>
            </v-col>
          </v-row>
          <v-form @submit.prevent="addHideDelivery">
            <v-row>
              <v-col>
                <v-text-field class="mb-2" label="Driver Name" v-model="hideDeliveryDetail.driverName"
                  :rules="[rules.required]" />
                <v-text-field class="mb-2" label="Car Plate No" v-model="hideDeliveryDetail.carPlateNo"
                  :rules="[rules.required]" />
                <VueDatePicker class="mb-9" :is-24="false" v-model="hideDeliveryDetail.dispatchedAt" time-picker-inline
                  disable-year-select :enable-time-picker="true" auto-apply format="dd/MMM/yyyy hh:mm aa"
                  :text-input="{ format: 'dd/MMM/yyyy hh:mm aa' }" model-type="dd-MMM-yyyy hh:mm aa"
                  placeholder="Dispatched At" />
                <v-text-field class="mb-2" label="Dispatched By" v-model="hideDeliveryDetail.dispatchedBy"
                  :rules="[rules.required]" />

                <v-text-field class="mb-2" label="Sheep Count" v-model="hideDeliveryDetail.sheepCount" type="number" />
                <v-text-field class="mb-2" label="Goat Count" v-model="hideDeliveryDetail.goatCount" type="number" />
                <v-text-field class="mb-2" label="Cow Count" v-model="hideDeliveryDetail.cowCount" type="number" />
                <v-text-field class="mb-2" label="Camel Count" v-model="hideDeliveryDetail.camelCount" type="number" />
              </v-col>
            </v-row>
            <v-row>
              <v-col align="end">
                <v-btn type="submit" :disabled="!isFormValid" color="primary" class="mt-3">Save</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-alert type="error" v-if="errorMessage">
        {{ errorMessage }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, reactive, onMounted, computed, inject } from 'vue';
import { addHideDeliveryDetail } from '@/services/hideCollectionService';
import { tender_getActive } from '@/services/tenderService'
import { useRouter } from 'vue-router';

import { useAuthStore } from '@/setup/stores/authStore';

const snackbar = inject("snackBar");
const loader = inject("loader");

const router = useRouter();
const authStore = useAuthStore();

const hideDeliveryDetail = reactive({
  tenderId: null,
  organizationId: null,
  driverName: '',
  carPlateNo: '',
  sheepCount: 0,
  goatCount: 0,
  cowCount: 0,
  camelCount: 0,
  dispatchedBy: '',
  dispatchedAt: null,
  status: '',
});

// const hideDeliveryDetails = ref([]);
const tender = ref([]);
const errorMessage = ref("");

const rules = {
  required: value => !!value || 'Required.',
};

const isFormValid = computed(() => {
  return hideDeliveryDetail.driverName &&
    hideDeliveryDetail.carPlateNo &&
    hideDeliveryDetail.dispatchedBy &&
    hideDeliveryDetail.dispatchedAt
});

function navigateHideDeliveryList() {
  router.push(`/hide-delivery-list`);
}

async function addHideDelivery() {
  try {
    loader.show();

    hideDeliveryDetail.tenderId = tender.value?.tenderId;
    hideDeliveryDetail.organizationId = authStore.user.organizationId;
    const createdDetail = await addHideDeliveryDetail(hideDeliveryDetail);
    // hideDeliveryDetails.value.push(createdDetail);

    // Object.keys(hideDeliveryDetail).forEach(key => {
    //     if (["goatCount", "cowCount", "camelCount", "sheepCount"].includes(key)) {
    //     hideDeliveryDetail[key] = 0;
    //     } else {
    //     hideDeliveryDetail[key] = '';
    //     }
    // });
    // hideDeliveryDetail.dispatchedAt = null;

    if (createdDetail) {
      snackbar.success("Hide delivery details added");
      navigateHideDeliveryList();
    }
  } catch (error) {
    snackbar.error(error);
    errorMessage.value = error.message;
  }
  loader.hide();

}

async function getData() {
  try {
    tender.value = await tender_getActive();
  } catch (error) {
    console.log(error)
    errorMessage.value = error.message;
  }
}


onMounted(async () => {
  await getData();
});
</script>