<template>
  <v-row>
    <v-col cols="12">
        <v-card>
          <v-card-title>
            <div class="d-flex">
              <h3>Hide Collection Summary</h3>
              <v-spacer />
              <v-btn size="small" href="/hide-detail-admin-list">View Detail</v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <table>
              <thead>
                <tr>
                  <th>Divisions</th>
                  <th>Sheep</th>
                  <th>Goat</th>
                  <th>Cow</th>
                  <th>Camel</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="report in data.hideDetailReport" :key="report.organizationId">
                  <td>{{ report.organizationName }}</td>
                  <td>{{ report.totalSheep }}</td>
                  <td>{{ report.totalGoat }}</td>
                  <td>{{ report.totalCow }}</td>
                  <td>{{ report.totalCamel }}</td>
                </tr>
                <tr>
                <td><strong>Total</strong></td>
                <td><strong>{{ totalSheep(data.hideDetailReport) }}</strong></td>
                <td><strong>{{ totalGoat(data.hideDetailReport) }}</strong></td>
                <td><strong>{{ totalCow(data.hideDetailReport) }}</strong></td>
                <td><strong>{{ totalCamel(data.hideDetailReport) }}</strong></td>
              </tr>
              </tbody>
            </table>
          </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <div class="d-flex">
            <h3>Hide Delivery Summary</h3>
            <v-spacer />
            <v-btn size="small" href="/hide-delivery-admin-list">View Detail</v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <table>
            <thead>
              <tr>
                <th>Divisions</th>
                <th>Sheep</th>
                <th>Goat</th>
                <th>Cow</th>
                <th>Camel</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in data.hideDeliveryReport" :key="report.organizationId">
                <td>{{ report.organizationName }}</td>
                <td>{{ report.totalSheep }}</td>
                <td>{{ report.totalGoat }}</td>
                <td>{{ report.totalCow }}</td>
                <td>{{ report.totalCamel }}</td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{{ totalSheep(data.hideDeliveryReport) }}</strong></td>
                <td><strong>{{ totalGoat(data.hideDeliveryReport) }}</strong></td>
                <td><strong>{{ totalCow(data.hideDeliveryReport) }}</strong></td>
                <td><strong>{{ totalCamel(data.hideDeliveryReport) }}</strong></td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { onMounted, inject, reactive } from 'vue';
import { getReport } from '@/services/hideCollectionService';

const loader = inject("loader");
const snackbar = inject("snackBar");

var data = reactive({
    hideDetailReport: [],
    hideDeliveryReport: []
  });

async function getData() {
  loader.show();
  try {
    const result = await getReport();
    Object.assign(data, result);
  } catch (error) {
    snackbar.error(error.message);
  } finally {
    loader.hide();
  }
}

function totalSheep(reports) {
  return reports.reduce((sum, report) => sum + report.totalSheep, 0);
}

function totalGoat(reports) {
  return reports.reduce((sum, report) => sum + report.totalGoat, 0);
}

function totalCow(reports) {
  return reports.reduce((sum, report) => sum + report.totalCow, 0);
}

function totalCamel(reports) {
  return reports.reduce((sum, report) => sum + report.totalCamel, 0);
}
onMounted(async () => {
  await getData();
});
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th, 
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}
</style>
