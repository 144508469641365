<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row class="mb-5">
            <v-col>
              <v-btn type="button" color="info" class="mt-3" @click="navigateHideDeliveryList()">Hide Delivery
                List</v-btn>
            </v-col>
          </v-row>
          <v-form @submit.prevent="updateHideDelivery">
            <v-row>
              <v-col cols="6">
                <v-text-field class="mb-2" label="Driver Name" v-model="hideDeliveryDetail.driverName"
                  :rules="[rules.required]" />
                <v-text-field class="mb-2" label="Car Plate No" v-model="hideDeliveryDetail.carPlateNo"
                  :rules="[rules.required]" />
                <VueDatePicker class="mb-9" v-model="hideDeliveryDetail.dispatchedAt" time-picker-inline
                  disable-year-select :enable-time-picker="true" auto-apply format="dd/MMM/yyyy hh:mm aa"
                  :text-input="{ format: 'dd/MMM/yyyy hh:mm aa' }" model-type="dd/MMM/yyyy hh:mm aa"
                  placeholder="Dispatched At" />
                <v-text-field class="mb-2" label="Dispatched By" v-model="hideDeliveryDetail.dispatchedBy"
                  :rules="[rules.required]" />

              </v-col>
              <v-col cols="6">
                <v-text-field class="mb-2" label="Sheep Count" v-model="hideDeliveryDetail.sheepCount" type="number" />
                <v-text-field class="mb-2" label="Goat Count" v-model="hideDeliveryDetail.goatCount" type="number" />
                <v-text-field class="mb-2" label="Cow Count" v-model="hideDeliveryDetail.cowCount" type="number" />
                <v-text-field class="mb-2" label="Camel Count" v-model="hideDeliveryDetail.camelCount" type="number" />

              </v-col>
            </v-row>
            <v-btn type="submit" :disabled="!isFormValid" color="primary" class="mt-3">Save</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-alert type="error" v-if="errorMessage">
        {{ errorMessage }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, reactive, onMounted, computed, inject } from 'vue';
import { getDeliveryDetailById, updateHideDeliveryDetail } from '@/services/hideCollectionService';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/setup/stores/authStore';
import moment from 'moment';

const snackbar = inject("snackBar");
const loader = inject("loader");
const authStore = useAuthStore()
const router = useRouter();

const hideDeliveryDetail = reactive({
  hideDeliveryId: null, // Assuming id is the unique identifier for the HideDeliveryDetail
  tenderId: null,
  organizationId: null,
  driverName: '',
  carPlateNo: '',
  sheepCount: 0,
  goatCount: 0,
  cowCount: 0,
  camelCount: 0,
  dispatchedBy: '',
  dispatchedAt: null,
  status: '',
});



// Extract hideDeliveryId from the route params
const hideDeliveryId = ref(null);

const errorMessage = ref("");

const rules = {
  required: value => !!value || 'Required.',
};

const isFormValid = computed(() => {
  return hideDeliveryDetail.driverName &&
    hideDeliveryDetail.carPlateNo &&
    hideDeliveryDetail.dispatchedBy &&
    hideDeliveryDetail.dispatchedAt
});

function navigateHideDeliveryList() {
  router.push(`/hide-delivery-list`);
}

async function updateHideDelivery() {
  try {
    loader.show();
    hideDeliveryDetail.organizationId = authStore.user.organizationId;
    await updateHideDeliveryDetail(hideDeliveryDetail);
snackbar.success("Hide delivery details updated");
    navigateHideDeliveryList();

  } catch (error) {
    snackbar.error(error);
    errorMessage.value = error.message;
  }
  loader.hide();
}
async function getHideDeliveryDetailById(Id) {
  try {

    const response = await getDeliveryDetailById(Id);
    //Populate hideDeliveryDetail with the fetched data

    if (response.dispatchedAt) {
      response.dispatchedAt = moment(response.dispatchedAt).format('DD/MMM/yyyy hh:mm A');
    }
    Object.assign(hideDeliveryDetail, response);


  } catch (error) {
    snackbar.error(error);
    errorMessage.value = error.message;
  }
}

// Populate hideDeliveryDetail with existing data when the component mounts
onMounted(async () => {
  hideDeliveryId.value = router.currentRoute.value.params.id;
  await getHideDeliveryDetailById(hideDeliveryId.value);
});
</script>
