<template>
    <v-container fluid>
        <v-btn @click="printReport" color="primary">Print</v-btn>
        <div v-html="report_template"></div>
    </v-container>
</template>

<script setup>
import { ref, defineProps, onMounted, inject } from "vue";
import * as tenderService from "@/services/tenderService";
import * as tdService from "@/services/tenderDetailService";
import moment from "moment";
// import { loadRouteLocation } from "vue-router";

const loader = inject("loader");

const props = defineProps({
    tenderId: {
        type: String,
        required: true,
    },
})

var tender = {};
var reportData = [];

var report_template = ref("");
var report = "";

async function getTemplate() {
    var temp = "";

    fetch('/reports/tender.html').then(response => response.text()).then(data => {
        temp = data;

        temp = temp.replace("#HIJRIYEAR", `${tender.hijriYear}H`);
        temp = temp.replace("#TENDERDATE", new moment(tender.tenderDate).format("D-MMM-yyyy"));

        var table_template = "";

        fetch('/reports/tender_item.html').then(response => response.text()).then(data => {

            reportData.forEach(rpt => {
                var temp_item = data;

                temp_item = temp_item.replace("#ITEMNAME", rpt.itemName);

                var items = "";

                rpt.prices.forEach(it => {
                    items += `<tr><td>${it.customerName}</td><td>${it.price}</td></tr>`;
                });

                temp_item = temp_item.replace("#ITEMS", items);

                table_template += temp_item;
            });

            temp = temp.replace("#ITEMTABLES", table_template);
            report_template.value = temp;
            report = temp;
        });
    });
}

function printReport() {
    var newWindow = window.open("about:blank", "_blank");
    newWindow.document.write(report);
    newWindow.document.close();
    newWindow.print();
}

onMounted(async () => {
    var promises = [tenderService.tender_getById(props.tenderId), tdService.getReportByTenderId(props.tenderId)];

    loader.show();
    const [getTender, getReport] = await Promise.all(promises);

    tender = getTender;
    reportData = getReport;

    console.log(reportData);

    loader.hide();
    
    await getTemplate();
});
</script>