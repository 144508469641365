<template>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-3">
                <v-card-text class="d-flex">
                    <v-row>
                        <v-col md="6" cols="12" class="d-flex">
                            <v-text-field label="Search" v-model="pageContext.search" hideDetails class="mr-2"
                                @keydown.enter="getData" />
                        </v-col>
                        <v-col md="6" cols="12" class="d-flex">
                            <v-btn icon="mdi-filter-multiple" size="small"
                                @click="showFilterDialog = !showFilterDialog" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="d-flex">
                    <v-row>
                        <v-col cols="6">
                        <v-toolbar color="grey-lighten-4" title="Total Arrived">
                            <v-btn stacked>
                            <v-badge
                                :content="totalGoatCount"
                                inline
                            >
                            </v-badge>
                            Goats
                            </v-btn>

                            <v-btn stacked>
                            <v-badge
                                :content="totalCowCount"
                                inline
                            >
                            </v-badge>
                            Cows
                            </v-btn>
                            
                            <v-btn stacked>
                                <v-badge
                                    :content="totalSheepCount"
                                    inline
                                ></v-badge>
                            Sheeps
                            </v-btn>
                            <v-btn stacked>
                                <v-badge
                                    :content="totalCamelCount"
                                    inline
                                ></v-badge>
                            Camels
                            </v-btn>
                        </v-toolbar>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
          <v-card>
          <v-card-text>
            <data-table :tableData="tableData" @pageChanged="pageChanged">
                <template v-slot:[`item.dispatchedAt`]="{ item }">
                    {{ formatDate(item.dispatchedAt) }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getStatusColor(item.status)" text-color="white">
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.Arrived`]="{ item }">
                    <v-btn icon="mdi-check" size="x-small" :disabled="item.status === 'Arrived'" :color="getStatusColor(item.status)"
                    @click="updateDeliveryStatus(item.hideDeliveryId)" />
                </template>
            </data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showFilterDialog" width="500" title="Filters">
        <v-card>
            <v-card-title>
                Filters
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete label="Teams" v-model="filters.organizationId" :items="orgs" item-title="name"
                            item-value="organizationId" />
                        <v-row>
                            <v-col class="d-flex">
                                <v-btn @click="clearFilters" color="grey" class="mr-2">Clear</v-btn>
                                <v-btn @click="applyFilters">Filter</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </template>
  
  <script setup>
  import { reactive, onMounted, inject, ref, computed } from 'vue';
  import { getAllDeliveryDetail, updateHideDeliveryDetail } from '@/services/hideCollectionService';
  import * as utService from '@/services/utilitiesService';
  import moment from 'moment';


const loader = inject("loader");
const snackbar = inject("snackBar");


  const pageContext = reactive({
    search: "",
    pageNumber: 1,
    pageSize: 20,
    organizationId: 0
  });
  const filters = reactive({
    organizationId: null, 
  });
  
const tableData = reactive({
  headers: [
    { title: "Division", key: "organization.name" },
    { title: "Driver Name", key: "driverName" },
    { title: "Car Plate No", key: "carPlateNo" },
    { title: "Goat Count", key: "goatCount" },
    { title: "Cow Count", key: "cowCount" },
    { title: "Sheep Count", key: "sheepCount" },
    { title: "Camel Count", key: "camelCount" },
    { title: "Dispatched By", key: "dispatchedBy" },
    { title: "Dispatched At", key: "dispatchedAt" },
    { title: "Status", key: "status" },
    { title: "Arrived", key: "Arrived" }
  ],
  data: [],
  itemsPerPage: 15,
  totalItems: 0,
  paginationContext: {},
});

const showFilterDialog = ref(false);
const orgs = ref([]);

function formatDate(date) {
  return moment(date).format('DD MMM yyyy hh:mm a');

}

function getHideDeliveryDetailById(id) {
  const hideDeliveryDetail = tableData.data.find(item => item.hideDeliveryId === id);
  return { ...hideDeliveryDetail };
}
async function updateDeliveryStatus(id) {
  try {
    loader.show();
    const hideDeliveryDetail = getHideDeliveryDetailById(id)
    hideDeliveryDetail.status = "Arrived";
    hideDeliveryDetail.organization = null;
    await updateHideDeliveryDetail(hideDeliveryDetail);
    await getData()
    snackbar.success("Hide delivery details updated");

  } catch (error) {
    snackbar.error(error);

  }
  loader.hide();
}


async function getData() {
  loader.show();

  let result = await getAllDeliveryDetail(pageContext)
    .catch(error => console.log(error))
    .finally(() => {
      loader.hide();
    });

  if (result) {
    tableData.data = result.items;
    tableData.paginationContext = {
      pageSize: pageContext.pageSize,
      currentPage: result.currentPage,
      totalCount: result.totalCount,
      totalPages: result.totalPages,
      hasNext: result.hasNext,
      hasPrevious: result.hasPrevious,
    };
  }
}

function getStatusColor(status) {
  switch (status) {
    case 'Dispatched':
      return 'info';
    case 'Arrived':
      return 'success';
    default:
      return '';
  }
}

async function pageChanged(pageNo) {
  pageContext.pageNumber = pageNo;
  await getData();
}

async function applyFilters() {
  pageContext.organizationId = filters.organizationId ?? 0;
  pageContext.pageNumber = 1;

  await getData();

  showFilterDialog.value = !showFilterDialog.value;
}

async function clearFilters() {
  filters.organizationId = null;

  pageContext.organizationId = filters.organizationId ?? 0;
  pageContext.pageNumber = 1;

  await getData();
  showFilterDialog.value = !showFilterDialog.value;
}
const totalSheepCount = computed(() => {
  return tableData.data.filter(item => item.status === "Arrived").reduce((sum, item) => sum + item.sheepCount, 0);
});

const totalGoatCount = computed(() => {
  return tableData.data.filter(item => item.status === "Arrived").reduce((sum, item) => sum + item.goatCount, 0);
});

const totalCowCount = computed(() => {
  return tableData.data.filter(item => item.status === "Arrived").reduce((sum, item) => sum + item.cowCount, 0);
});

const totalCamelCount = computed(() => {
  return tableData.data.filter(item => item.status === "Arrived").reduce((sum, item) => sum + item.camelCount, 0);
});

onMounted(async () => {
  orgs.value = await utService.getAllOrganizations(5)
    .catch(error => console.log(error));
  await getData();
});
</script>
