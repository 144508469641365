<template>
  <v-snackbar
    v-model="display"
    :color="color"
    :timeout="timeout"
    location="top right"
  >
    {{ message }}
  </v-snackbar>
</template>
<script setup>
import { ref, inject } from "vue";

const emitter = inject("emitter");

const display = ref(false);
const message = ref("");
const color = ref("success");
const timeout = ref(1500);

emitter.on("showSnackbar", ([messageArg, colorArg, timeoutArg]) => {
  message.value = messageArg;
  color.value = colorArg;
  timeout.value = timeoutArg;
  display.value = true;
});
</script>
