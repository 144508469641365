import axios from "axios";
import { useAuthStore } from "./stores/authStore";

axios.defaults.baseURL = "https://api.bgtpakistan.com/api/v1/";
// axios.defaults.baseURL = "https://localhost:7019/api/v1/";

export const apiCall = (method, url, params = null, isBlob = false) => {
  const authStore = useAuthStore();

  return new Promise((resolve, reject) => {
    if (params != null) {
      if (method == "get") {
        let i = 0;
        Object.keys(params).forEach((key) => {
          url += `${i == 0 ? "?" : "&"}${key}=${params[key]}`;
        });
      }
    }

    var header = {};

    if (authStore.isAuthenticated)
      header = { headers: { Authorization: `Bearer ${authStore.token}` } };

    let axCall = null;

    if (isBlob)
      header.responseType = "blob";

    switch (method) {
      case "post":
        axCall = axios.post(url, params, header);
        break;
      case "put":
        axCall = axios.put(url, params, header);
        break;
      case "patch":
        axCall = axios.patch(url, params, header);
        break;
      case "delete":
        axCall = axios.delete(url, header);
        break;
      default:
        axCall = axios.get(url, header);
        break;
    }

    axCall
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        console.log(error);

        if (error.response) {
          if (error.response.status == 401) {
            authStore.logout();
          }
        }

        if (error.response)
          reject(error.response.data);
        else
          reject(error.message);
      });
  });
};

export const apiCall_FormData = (method, url, params) => {
  const authStore = useAuthStore();

  return new Promise((resolve, reject) => {
    let axCall = null;
    const header = { headers: { "Content-Type": "multipart/form-data" } };

    if (authStore.isAuthenticated)
      header.headers.Authorization = `Bearer ${authStore.token}`;

    switch (method) {
      case "post":
        axCall = axios.post(url, params, header);
        break;
      case "put":
        axCall = axios.put(url, params, header);
        break;
      case "patch":
        axCall = axios.patch(url, params, header);
        break;
    }

    axCall
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
