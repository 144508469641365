import ConfirmDialog from "./confirmDialog.vue";

export default {
  install: (app) => {
    const emitter = app.config.globalProperties.$emitter;

    app.component("ConfirmDialog", ConfirmDialog);

    function confirm(title, message, confirmText = "Ok", cancelText = "Cancel", isCritical = false) {
      return new Promise((resolve, reject) => {
        emitter.emit("confirm", { title, message, confirmText, cancelText, isCritical, resolve, reject });
      });
    }

    app.provide("confirmDialog", { confirm });
  },
};
