import { createApp } from 'vue'
import App from './App.vue'

// Config
import { useCreatedVuetify } from "@/setup/vuetify";
import { useCreatedRouter } from "@/setup/router";
import { createPinia } from "pinia";
import mitt from "mitt";

// Stores

// Custom Components
import dataTable from "@/components/dataTable";
import simpleDialog from "@/components/dialog/simpleDialog";
import datePicker from "@/components/datePicker";
import snackBar from "@/components/snackBar/snackBar.js";
import loader from "@/components/dialog/loadingDialog/loadingDialog.js"
import confirmDialog from "@/components/dialog/confirmDialog/confirmDialog.js"
import inputDialog from "@/components/dialog/inputDialog/inputDialog.js";

const pinia = createPinia();
const emitter = mitt();
const app = createApp(App);

app.use(useCreatedVuetify);
app.use(pinia);
app.use(useCreatedRouter);

// Using MITT to emit and listen to events
app.config.globalProperties.$emitter = emitter;
app.provide("emitter", emitter);

app.use(snackBar);
app.use(loader);
app.use(confirmDialog);
app.use(inputDialog);

app.component("DataTable", dataTable);
app.component("SimpleDialog", simpleDialog);
app.component("DatePicker", datePicker);

app.mount('#app')