<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" elevation="4" :rail="rail">
      <v-list-item :prepend-avatar="require(`@/assets/bgt_logo.jpg`)" title="Burhani Guards Pakistan"
        :subtitle="userName" nav class="my-2" />
      <v-divider></v-divider>
      <v-list density="compact" nav color="primary">
        <v-list-item prepend-icon="mdi-home" title="Home" to="/home" />

        <template v-for="module in modules" :key="module.moduleId">
          <template v-if="module.moduleId == 3">
            <v-list-group :value="module.moduleId">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" prepend-icon="mdi-cash-multiple" title="Finance"></v-list-item>
              </template>
              <v-list-item title="Division" :href="financeLink('division')" target="_blank" v-if="hasModule(4)" />
              <v-list-item title="Personal" :href="financeLink('member')" target="_blank" v-if="hasModule(13)" />
            </v-list-group>
          </template>

          <template v-else>
            <v-list-group :value="module.moduleId" v-if="module.children.length">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" :prepend-icon="module.linkIcon" :title="module.name" />
              </template>
              <v-list-item v-for="child in module.children" :key="child.moduleId" :prepend-icon="child.linkIcon ?? `mdi-circle-small`"
                :title="child.name" :to="child.linkName" />
            </v-list-group>

            <v-list-item :prepend-icon="module.linkIcon" :title="module.name" :to="module.linkName" v-else />
          </template>
        </template>

        <!--<v-list-item prepend-icon="mdi-account-group" title="Members List" to="/members" v-if="isIncharge" />
        <v-list-item prepend-icon="mdi-calendar-clock" title="Attendance" to="/attendance" v-if="isIncharge" />
        <v-list-group value="Finance">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-cash-multiple" title="Finance"></v-list-item>
          </template>
          <v-list-item title="Division" :href="financeLink('division')" target="_blank" v-if="isIncharge" />
          <v-list-item title="Personal" :href="financeLink('member')" target="_blank" />
        </v-list-group>
        <v-list-group value="User Access">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-account-lock-open" title="User Access"></v-list-item>
          </template>
          <v-list-item title="Role Permissions" to="user-roles" />
          <v-list-item title="User Permissions" to="user-rights" />
          <v-list-item title="User Data Permissions" to="user-rights" />
        </v-list-group>-->
        <template v-if="validateAuthOfficers">
          <v-list-item prepend-icon="mdi-database-search" title="Member Upliftment Form"
            to="/upliftment-form-response" />
        </template>
        <!-- <v-list-item prepend-icon="mdi-format-list-text" title="Sharaf Forms" to="/sharaf-forms" /> -->
      </v-list>
    </v-navigation-drawer>
    
    <v-app-bar rounded :class="`ma-4 floating-app-bar ${mobile ? 'mobile' : ''} ${rail ? 'rail' : ''}`">
      <v-app-bar-nav-icon icon="mdi-menu" @click="showHideDrawer()" />
      <v-app-bar-title>
        {{ route.meta.title }}
      </v-app-bar-title>
      <template v-slot:append>
        <v-btn href="https://bgtfinance.herokuapp.com/syncUsers?api_key=eca1811f-10d0-4e53-a017-43d78f138792" target="_blank" icon="mdi-sync" v-if="validateAuthOfficers"></v-btn>
        <v-btn @click="logout" icon="mdi-logout"></v-btn>
      </template>
    </v-app-bar>
    <v-main style="--v-layout-top: 80px">
      <v-container fluid>
        <router-view v-slot="{ Component }">
          <component :is="Component"></component>
        </router-view>
      </v-container>
    </v-main>

    <snack-bar />
    <loading-dialog />
    <confirm-dialog />
    <input-dialog />
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted, inject, toRaw } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from 'vuetify';
// import { useRouter } from "vue-router";
import { useAuthStore } from "@/setup/stores/authStore";
import * as modService from '@/services/modulesService';

const { mobile } = useDisplay();
const loader = inject("loader");

const route = useRoute();
// const router = useRouter();
const authStore = useAuthStore();

const drawer = ref(mobile.value ? false : true);
const rail = ref(false);

const modules = ref([]);

function showHideDrawer() {
  if (mobile.value)
    drawer.value = !drawer.value;
  else
    rail.value = !rail.value;
}

const userName = computed(() => {
  if (authStore.user)
    return authStore.user.username;
  else
    return "N/A";
});

// const isIncharge = computed(() => {
//   if (authStore.user)
//     return authStore.user.isIncharge;
//   else
//     return false;
// });

 function financeLink(type) {
   if (!authStore.user)
     return;

   var jwt = `${authStore.user.itsId}&&eca1811f-10d0-4e53-a017-43d78f138792&&${Date.now()}`;
   return `http://accounts.bgtpakistan.com/#/login?jwt=${btoa(jwt)}&type=${type}`;
}

function moduleExists(arr, moduleId) {
  for (let mod of arr) {
    if (mod.moduleId === moduleId) {
      return true; // Match found at the current level.
    }

    if (mod.children && mod.children.length) {
      // Recursively check in children.
      const foundInChildren = moduleExists(mod.children, moduleId);
      if (foundInChildren) {
        return true; // Match found in children.
      }
      // Note: No return here, to allow the loop to continue if not found in children.
    }
  }

  // Return false only after checking all items in the array and their children.
  return false;
}

function hasModule(moduleId) {
  return moduleExists(toRaw(modules.value), moduleId);
}

async function getModules() {
  loader.show();

  modules.value = await modService.getModules()
    .catch(ex => console.log(ex));

  loader.hide();
}

function logout() {
  authStore.logout();
}

const authOfficers = ref([
  40496719,
  40476109,
  40419031,
  40480238,
  40452802,
  40480239,
  40496185
]);

const validateAuthOfficers = computed(() => {
  if (!!authStore.user && !!authStore.user.itsId)
    return authOfficers.value.includes(authStore.user.itsId);
  else
    return false;
});

onMounted(async () => {
  await getModules();
});

// console.log(authStore.user);

</script>

<style>
.floating-app-bar {
  width: calc(100% - 288px) !important;
}

.floating-app-bar.mobile {
  width: calc(100% - 32px) !important;
}

.floating-app-bar.rail {
  width: calc(100% - 88px) !important;
}
</style>
