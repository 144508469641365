<template>
  <v-dialog v-model="display" width="auto" persistent>
    <v-card>
      <v-card-text class="d-flex align-center">
        <v-progress-circular indeterminate color="primary" />
        <h5 class="ml-4">{{ message }}</h5>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, inject } from "vue";

const emitter = inject("emitter");

const display = ref(false);
const message = ref("Loading, Please Wait...");

emitter.on("toggleLoader", (show) => {
  display.value = show;
});
</script>
