<template>
    <v-form @submit.prevent="saveData">
        <v-row>
          <v-col>
            <v-text-field label="Contact Person" v-model="customer.contactName" />
            <v-text-field label="Company Name" v-model="customer.companyName" />
            <v-text-field label="Mobile" v-model="customer.mobile" />
            <v-text-field label="Phone" v-model="customer.phone" />
            <v-text-field label="Email" v-model="customer.email" />
            <v-text-field label="Address" v-model="customer.address" />
          </v-col>
        </v-row>
        <v-row>
            <v-col align="end">
                <v-btn type="button" variant="text" class="mr-2" @click="emit('onCancel')">Cancel</v-btn>
                <v-btn type="submit">Save</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script setup>
import { ref, inject } from "vue";
import * as tcService from "@/services/tenderCustomerService";

const loader = inject("loader");
const snackbar = inject("snackBar");
const emit = defineEmits("onSave", "onCancel");

const customer = ref({
    customerId: 0,
    contactName: "",
    companyName: "",
    phone: "",
    mobile: "",
    email: "",
    address: "",
});

async function saveData() {
    loader.show();

    console.log(customer.value);

    const result = await tcService.add(customer.value)
        .catch(ex => snackbar.error(ex));

    if (result)
        emit("onSave");

    loader.hide();
}

</script>