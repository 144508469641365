<template>
    <v-container>
      <v-row>
        <v-col>
          <data-table :tableData="tableData">
            <template v-slot:[`item.Actions`]="{ index }">
            <!-- View Button -->
            <v-btn icon="mdi-eye" size="small" @click="viewEntry(index)" class="mr-2"></v-btn>
            
            <!-- Delete Button -->
            <v-btn icon="mdi-delete" size="small" @click="deleteEntry(index)"></v-btn>
        </template>
          </data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert type="error" icon="mdi-alert-circle" title="Error" :text="`Unable to open form: ${errorMessage}`"
            class="my-2" v-if="errorMessage != null && errorMessage.length > 0" />
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script setup>
  /* eslint-disable vue/valid-v-slot */
  
  import { getAllReponses, deleteById } from '@/services/memberUpliftmentFormService';
  import { ref, reactive, onMounted, inject } from 'vue';
  import { useRouter } from 'vue-router';


  const errorMessage = ref("");
  const loader = inject("loader");
    const router = useRouter();

    
  const tableData = reactive({
    headers: [
      { title: 'Member', key: 'fullName' },
      { title: 'Division', key: 'division' },
      { title: 'Required Upliftment', key: 'requiredUpliftment' },
      { title: 'Total Amount Needed (PKR)', key: 'totalContribution' },
      { title: 'Inayat Amount', key: 'inayatAmount' },
      { title: 'Remarks', key: 'remarks' },
      { title: 'Actions', key: 'Actions' }
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
  });
  
  
  const loadEntries = async () => {
    loader.show();
    errorMessage.value = "";
  
    let response = await getAllReponses()
      .catch(error => errorMessage.value = error)
      .finally(async () => {
        loader.hide();
      });
  
    if (response != null && !errorMessage.value && response.length > 0) {
      tableData.data = response.map(entry => ({
        ...entry,
        fullName: entry.member?.fullName,
        division: entry.member?.organization?.name,
      }));
  
    }
  };

  const viewEntry = (index) => {
    const entry = tableData.data[index];
    router.push(`/upliftment-form-detailed/${entry.id}`);
};
  
  
  const deleteEntry = async (index) => {
    let c = confirm("Are you sure you want to delete?")
    if (!c) {
      return;
    }
    loader.show();
    const entry = tableData.data[index];
    await deleteById(entry.id)
      .finally(async () => {
        await loadEntries();
        loader.hide();
      });
  };
  
  
  onMounted(async () => {
    await loadEntries();
  });
  </script>