<template>
  <v-card>
    <v-card-text class="d-flex justify-space-between">
      <v-btn href="/tender" size="small">Dashboard</v-btn>
    </v-card-text>
  </v-card>

  <v-card class="mt-2">
    <v-card-text>
      <v-btn-toggle v-model="selectedIndex" color="primary" class="mb-5" density="compact" variant="elevated" elevation="2">
        <v-btn>Entry Form</v-btn>
        <v-btn>Report</v-btn>
      </v-btn-toggle>

      <entry-form v-if="selectedIndex == 0" :tender-id="tenderId" />
      <report-form v-if="selectedIndex == 1" :tender="tender" :tender-id="tenderId" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import EntryForm from "./Components/EntryForm.vue";
import ReportForm from "./Components/ReportForm.vue";

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const tenderId = route.params.tenderId;

var selectedIndex = ref(0);

onMounted(async () => {

});
</script>
