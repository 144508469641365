<template>
    <v-row>
        <v-table class="w-100">
            <thead>
                <tr>
                    <th>Division</th>
                    <th v-for="head in heads" :key="head.expenseHeadId">{{ head.expenseHead }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="org in filteredOrgs" :key="org.organizationId">
                    <td>{{ org.name }}</td>
                    <td v-for="head in heads" :key="head.expenseHeadId">{{ getSum(org.organizationId,
                        head.expenseHeadId) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-row>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps(['orgs', 'heads', 'expenses']);

const filteredOrgs = ref([]);

function getSum(orgId, expenseHeadId) {
    var exp = props.expenses.filter(x => x.organizationId == orgId && x.expenseHeadId == expenseHeadId);
    return exp.reduce((n, { amount }) => n + amount, 0);
}

onMounted(() => {
    filteredOrgs.value = props.orgs.filter(org => props.expenses.some(expense => expense.organizationId === org.organizationId));
});

// console.log(props.expenses);
</script>