import { apiCall, apiCall_FormData } from "@/setup/webApi";

export const getTeamReponses = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberUpliftmentForm/responses`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getAllReponses = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberUpliftmentForm/responses/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `MemberUpliftmentForm/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getSingleResponse = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `MemberUpliftmentForm/responses/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const submitResponse = (postData) => {
    return new Promise((resolve, reject) => {
      apiCall_FormData("post", `MemberUpliftmentForm/response`, postData)
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  };