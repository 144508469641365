import { apiCall } from "@/setup/webApi";

export const getActive = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SharafForm/active`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SharafForm/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getEligibleMembers = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SharafForm/${id}/members`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addRegistration = (id, itsId, remarks) => {
  const formData = {
    memberItsId: itsId,
    remarks: remarks
  }

  return new Promise((resolve, reject) => {
    apiCall("post", `SharafForm/${id}`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getRegistrations = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `SharafForm/${id}/detail`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteRegistration = (id, itsId) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `SharafForm/${id}/${itsId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};