import { apiCall } from "@/setup/webApi";

export const getAll = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getActive = () => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/active`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getItemsByFormId = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/${id}/items/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getDetailByFormId = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/${id}/detail/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getAvailableSiparasByFormId = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/${id}/siparas/available`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getSiparasByFormId = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `TabudaatForm/${id}/siparas`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addSipara = (id, formData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `TabudaatForm/${id}/siparas`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const addDetail = (id, answers) => {
  var formData = {
    items: answers,
  }

  return new Promise((resolve, reject) => {
    apiCall("post", `TabudaatForm/${id}/detail`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};