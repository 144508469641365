<template>
    <v-row>
        <v-col cols="12">
            <template v-if="'sharafTitle' in sharaf">
                <v-card>
                    <v-card-text>
                        <h6 class="text-h6 font-weight-bold">
                            {{ sharaf.sharafTitle }}<br />
                            <small><span class="text-red">From: {{ moment(sharaf.startDate).format("D-MMM-yyyy") }} To: {{
                                moment(sharaf.endDate).format("D-MMM-yyyy") }}</span></small>
                        </h6>
                        <h6 class="text-subtitle-1" v-html="sharaf.description"></h6>
                    </v-card-text>
                </v-card>
                <v-card class="mt-2">
                    <v-card-text>
                        <v-form @submit.prevent="addMember">
                            <v-row>
                                <v-col>
                                    <v-autocomplete label="Member" v-model="selectedMember.memberItsId" :items="members" item-title="fullName"
                                        item-value="itsId" :custom-filter="customFilter" hide-details>
                                        <template v-slot:item="{ props, item }">
                                            <v-list-item v-bind="props" :prepend-avatar="item?.raw?.pictureInIts"
                                                :title="item?.raw?.fullName" :subtitle="item?.raw?.itsId"></v-list-item>
                                        </template>
                                    </v-autocomplete>
                                    <v-text-field label="Remarks" v-model="selectedMember.remarks" hide-details class="my-2" />
                                    <v-btn type="submit" prepend-icon="mdi-plus-circle" class="float-right">Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-divider />
                    <v-card-text>
                        <v-list density="compact" three-line v-if="registrations.length > 0">
                            <v-list-item v-for="member in registrations" :key="member.itsId" density="compact" :prepend-avatar="member.member.pictureInUniform">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ `${member.member.itsId} - ${member.member.fullName}` }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ member.member.rank.description }} - {{ member.member.organization.name }} <br/>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        {{ member.remarks }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <template v-slot:append>
                                    <v-btn icon="mdi-delete" size="small" color="error" @click="deleteMember(member.member.itsId)" />
                                </template>
                            </v-list-item>
                        </v-list>
                        <h3 v-else class="text-center">No Data Found</h3>
                    </v-card-text>
                </v-card>
            </template>
            <v-alert type="error" 
                    icon="mdi-alert-circle" 
                    title="Error"
                    :text="`Unable to open form: ${errorMessage}`"
                    class="my-2"
                    v-if="errorMessage != null && errorMessage.length > 0" />
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, reactive, inject, onMounted } from 'vue';
import { useRoute } from "vue-router";
import { getById, getEligibleMembers, getRegistrations, addRegistration, deleteRegistration } from '@/services/sharafService';
import moment from 'moment';

const loader = inject("loader");
const snackbar = inject("snackBar");
const route = useRoute();

const sharafId = route.params.id;
const sharaf = ref({});
const members = ref([]);
const registrations = ref([]);
const selectedMember = reactive({
    memberItsId: null,
    remarks: null
});

const errorMessage = ref("");

function customFilter(itemTitle, queryText, item) {
    const fullName = item.raw.fullName.toLowerCase();
    const itsId = item.raw.itsId.toString();
    const searchText = queryText.toLowerCase();

    console.log(itemTitle);
    return fullName.indexOf(searchText) > -1 || itsId.indexOf(searchText);
}

async function getData() {
    loader.show();
    errorMessage.value = "";

    getById(sharafId)
        .then(result => sharaf.value = result)
        .catch(error => errorMessage.value = error)
        .finally(async () => {
            await getMembers();
            await getDetail();
            loader.hide();
        });
}

async function getMembers() {
    let result = await getEligibleMembers(sharafId)
        .catch(error => console.log(error))

    members.value = result;
}

async function getDetail() {
    let result = await getRegistrations(sharafId)
        .catch(error => console.log(error))

    registrations.value = result;
}

async function addMember() {
    if (selectedMember.memberItsId == null) {
        snackbar.error("Please select a member");
        return;
    }

    loader.show();

    await addRegistration(sharafId, selectedMember.memberItsId, selectedMember.remarks)
        .catch(error => {
            snackbar.error(error);
            console.log(error);
        })
        .finally(async () => {
            await getDetail();
            loader.hide();
        });

        selectedMember.memberItsId = null;
        selectedMember.remarks = null;
}

async function deleteMember(itsId) {
    loader.show();

    await deleteRegistration(sharafId, itsId)
        .finally(async () => {
            await getDetail();
            loader.hide();
        });
}

onMounted(async () => {
    await getData();
});

</script>

<style scoped>
.v-list-item--one-line .multiline {
    -webkit-line-clamp: 10 !important;
}
</style>