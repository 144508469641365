<template>
    <v-row>
        <v-col md="4">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    Members
                    <v-btn size="small" @click="addUser">Add</v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-list v-if="users.length > 0" density="compact">
                        <template v-for="user in users" :key="user.itsId">
                            <v-list-item :title="user.fullName" :subtitle="user.organization" :value="user" @click="selectUser(user)">
                                <template v-slot:append>
                                    <v-btn variant="text" size="sm" icon="mdi-delete" color="error"
                                        @click.stop="deleteUser(user)" />
                                </template>
                            </v-list-item>
                        </template>
                    </v-list>
                    <h5 class="text-subtitle-2 text-center pa-5" v-else>No Data Available</h5>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col md="8">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    Data Access
                    <v-btn size="small" @click="addPermission(false)">Add</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-table>
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th>Section</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="permission in permissions" :key="permission.permissionId">
                                <td>
                                    <v-autocomplete v-model="permission.organizationId" :items="organizations"
                                        item-title="name" item-value="organizationId" hide-details />
                                </td>
                                <td>
                                    <v-autocomplete v-model="permission.sectionId" :items="sections"
                                        item-title="description" item-value="generalID" hide-details />
                                </td>
                                <td>
                                    <v-autocomplete v-model="permission.statusId" :items="statuses"
                                        item-title="description" item-value="generalID" hide-details />
                                </td>
                                <td>
                                    <v-btn icon="mdi-delete" color="error" size="small"
                                        @click="deletePermission(permission)"></v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" align="end">
                                    <v-btn size="small" @click="updatePermissions">Save</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, reactive, inject, onMounted, toRaw } from "vue";
import * as acService from "@/services/accessControlService";
import * as utService from "@/services/utilitiesService";
import * as memService from '@/services/memberService';
import _clone from "lodash/clone";

const loader = inject("loader");
const confirmDialog = inject("confirmDialog");
const inputDialog = inject("inputDialog");
const snackbar = inject("snackBar");

const users = ref([]);
const permissions = ref([]);
const organizations = ref([]);
const sections = ref([]);
const statuses = ref([]);

let permission = reactive({
    permissionId: 0,
    organizationId: null,
    sectionId: null,
    statusId: null,
});

let selectedUser = reactive({});

async function getUsers() {
    loader.show();

    users.value = await acService.data_getUsersAll()
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function getData() {
    loader.show();

    permissions.value = await acService.data_getPermissions(selectedUser.itsId)
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function selectUser(user) {
    console.log(user);
    selectedUser = user;
    await getData();
}

async function addUser() {
    const answer = await inputDialog.input("Enter ITS Number", "");

    if (answer == null)
        return;

    let itsId = answer.answer;
    const hasMember = users.value.some(mem => mem.itsId == itsId);

    if (hasMember) {
        snackbar.error(`Its: ${itsId} already exists`);
        return;
    }

    var member = await memService.getById(itsId)
        .catch(ex => snackbar.error(ex));

    selectedUser = member;
    users.value.push(member);

    addPermission(true);
}

function addPermission(clear) {
    if (clear)
        permissions.value = [
            _clone(toRaw(permission)),
        ];
    else
        permissions.value.push(_clone(toRaw(permission)));
}

async function updatePermissions() {
    var isError = false;

    for (const permit of permissions.value) {
        if (permit.organizationId == null) {
            isError = true;
            snackbar.error("Please select team");
        }
    }

    if (!isError) {
        loader.show();
        const result = await acService.data_updatePermissions(selectedUser.itsId, permissions.value)
            .then(() => snackbar.success("Data rights have been saved"))
            .catch(ex => snackbar.error(ex));

        console.log(result);
        loader.hide();
    }
}

async function deleteUser(user) {
    var isDelete = await confirmDialog.confirm("Delete User", `Are you sure you want to delete user: ${user.fullName}? Please confirm.`, "Yes", "No", true);

    if (!isDelete)
        return;

    loader.show();

    await acService.data_deleteUser(user.itsId)
        .then(async () => {
            permissions.value = [];
            await getUsers();
        })
        .catch(ex => snackbar.error(ex));

    loader.hide();
}

async function deletePermission(permission) {
    var ix = permissions.value.indexOf(permission);

    var isDelete = await confirmDialog.confirm("Delete Permission", `Are you sure you want to delete this permission? Please confirm.`, "Yes", "No", true);

    if (!isDelete)
        return;

    permissions.value.splice(ix, 1);
}

onMounted(async () => {
    loader.show()
    organizations.value = await utService.getAllOrganizations()
        .catch(ex => snackbar.error(ex));

    sections.value = await utService.getSections()
        .catch(ex => snackbar.error(ex));

    statuses.value = await utService.getMemberStatus()
        .catch(ex => snackbar.error(ex));

    statuses.value.splice(0, 0, { generalId: 0, description: 'All' });
    sections.value.splice(0, 0, { generalId: 0, description: 'All' });

    await getUsers();
});
</script>