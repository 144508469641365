<template>
    <v-form @submit.prevent="save">
        <v-row>
            <v-col>
                <v-autocomplete label="Attendance Type" v-model="attendanceMaster.attendanceTypeId" :items="attendanceTypes"
                    item-title="description" item-value="generalID" required />
                <v-text-field v-model="attendanceMaster.description" :rules="textRules" label="Description" required />
                <v-row>
                    <v-col md="6">
                        <v-label>Duty Date</v-label>
                        <VueDatePicker v-model="attendanceMaster.dutyDate" time-picker-inline disable-year-select
                            :enable-time-picker="false" auto-apply format="dd/MMM/yyyy"
                            :text-input="{ format: 'dd/MMM/yyyy' }" model-type="dd-MMM-yyyy" placeholder="dd/MMM/yyyy" />
                    </v-col>
                    <v-col md="6">
                        <v-label>Reporting Time</v-label>
                        <VueDatePicker v-model="attendanceMaster.reportingTime" time-picker auto-apply
                            :text-input="{ format: 'hh:mm aa' }" model-type="hh:mm aa" :is-24="false"
                            placeholder="hh:mm am/pm" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-label>Scanning Start Time</v-label>
                        <VueDatePicker v-model="attendanceMaster.scanningStart" time-picker auto-apply
                            :text-input="{ format: 'hh:mm aa' }" model-type="hh:mm aa" :is-24="false"
                            placeholder="hh:mm am/pm" />
                    </v-col>
                    <v-col>
                        <v-label>Scanning End Time</v-label>
                        <VueDatePicker v-model="attendanceMaster.scanningEnd" time-picker auto-apply
                            :text-input="{ format: 'hh:mm aa' }" model-type="hh:mm aa" :is-24="false"
                            placeholder="hh:mm am/pm" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="end">
                        <v-btn @click="emit('onClose')" class="mr-2" variant="text">Cancel</v-btn>
                        <v-btn type="submit">Save</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-form>
</template>
<script setup>
import { ref, reactive, inject, defineEmits, onMounted } from "vue";
import * as atService from '@/services/attendanceService';
import * as utService from '@/services/utilitiesService';

const emit = defineEmits(["onSave", "onClose"]);

const loader = inject("loader");
const snackbar = inject("snackBar");

const attendanceTypes = ref([]);

let attendanceMaster = reactive({
    attendanceTypeId: null,
    organizationId: 0,
    description: null,
    dutyDate: null,
    reportingTime: null,
    scanningStart: null,
    scanningEnd: null
});

async function getAttendanceTypes() {
    loader.show();

    const result = await utService.getAttendanceTypes()
        .catch(ex => snackbar.error("Error", ex));

    if (result)
        attendanceTypes.value = result;

    loader.hide();
}

async function save() {
    if (!attendanceMaster.attendanceTypeId) {
        snackbar.error("Please select event type");
        return;
    }

    if (!attendanceMaster.description) {
        snackbar.error("Please enter a description");
        return;
    }

    if (!attendanceMaster.dutyDate || !attendanceMaster.reportingTime || !attendanceMaster.scanningStart || !attendanceMaster.scanningEnd) {
        snackbar.error("Please select duty dates and times");
        return;
    }

    loader.show();
    let attendance = { ...attendanceMaster };

    attendance.reportingTime = `${attendance.dutyDate} ${attendance.reportingTime}`;
    attendance.scanningStart = `${attendance.dutyDate} ${attendance.scanningStart}`;
    attendance.scanningEnd = `${attendance.dutyDate} ${attendance.scanningEnd}`;

    await atService.master_add(attendance)
        .catch(ex => snackbar.error(ex));

    snackbar.success("Event created successfully");
    emit("onSave");

    loader.hide();
}

onMounted(async () => {
    await getAttendanceTypes();
});
</script>